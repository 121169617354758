import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";

const AmPmRadioButtons = ({ value, onChange, colorScheme }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.radioButton,
          value === "AM" && styles.selected,
          colorScheme === "dark" && styles.radioButtonDark,
          value === "AM" && colorScheme === "dark" && styles.selectedDark,
        ]}
        onPress={() => onChange("AM")}
      >
        <Text
          style={[
            styles.radioText,
            value === "AM" && styles.selectedText,
            colorScheme === "dark" && styles.radioTextDark,
            value === "AM" && colorScheme === "dark" && styles.selectedTextDark,
          ]}
        >
          AM
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.radioButton,
          value === "PM" && styles.selected,
          colorScheme === "dark" && styles.radioButtonDark,
          value === "PM" && colorScheme === "dark" && styles.selectedDark,
        ]}
        onPress={() => onChange("PM")}
      >
        <Text
          style={[
            styles.radioText,
            value === "PM" && styles.selectedText,
            colorScheme === "dark" && styles.radioTextDark,
            value === "PM" && colorScheme === "dark" && styles.selectedTextDark,
          ]}
        >
          PM
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
  },
  radioButton: {
    borderWidth: 1,
    borderColor: "#007AFF",
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginHorizontal: 5,
  },
  radioButtonDark: {
    borderColor: "#0A84FF",
  },
  selected: {
    backgroundColor: "#007AFF",
  },
  selectedDark: {
    backgroundColor: "#0A84FF",
  },
  radioText: {
    color: "#007AFF",
    fontSize: 14,
  },
  radioTextDark: {
    color: "#0A84FF",
  },
  selectedText: {
    color: "#FFFFFF",
  },
  selectedTextDark: {
    color: "#000000",
  },
});

export default AmPmRadioButtons;
