import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Switch,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import TimeInput from "./TimeInput";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import UserStyles from "./UserStyles";
import TimesheetTests from "./TimesheetTests";

// Near the top of your file, after imports
console.log("Loading SpecialFormatTimesheet component");
console.log("Current NODE_ENV:", process.env.NODE_ENV);

// Only run tests if explicitly enabled
const ENABLE_TESTS = true; // Set to false to disable tests
if (ENABLE_TESTS) {
  try {
    console.log("Attempting to run tests...");
    TimesheetTests.runAllTests();
  } catch (error) {
    console.error("Error running tests:", error);
  }
}

const SpecialFormatTimesheet = ({
  currentWeek,
  colorScheme,
  onSaveDay,
  savedDays,
  isSubmitted,
  timesheetData,
  updateSpecialTimesheetData,
  addServiceToDay,
  removeServiceFromDay,
  employeeId,
  employeeName,
  prepareDayData,
  onWeeklyTotalChange,
}) => {
  const [weeklyTotal, setWeeklyTotal] = useState(0);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  // Set default timezone
  useEffect(() => {
    moment.tz.setDefault("America/Phoenix");
  }, []);

  // Helper function to calculate time difference
  const calculateTotalTime = (startTime, endTime) => {
    if (!startTime || !endTime) {
      console.log("Missing start or end time");
      return 0;
    }

    const start = moment(startTime, ["h:mm A", "HH:mm"]);
    const end = moment(endTime, ["h:mm A", "HH:mm"]);

    if (!start.isValid() || !end.isValid()) {
      console.log("Invalid time format:", { startTime, endTime });
      return 0;
    }

    // Handle overnight shifts
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    const duration = moment.duration(end.diff(start));
    const hours = parseFloat(duration.asHours().toFixed(2));

    console.log("Time calculation:", {
      start: start.format("h:mm A"),
      end: end.format("h:mm A"),
      hours,
    });

    return hours;
  };

  // Calculate daily totals with detailed logging
  const calculateDailyTotal = (day) => {
    console.log("Calculating daily total for:", day);

    if (!day || !Array.isArray(day.services)) {
      console.log("Invalid day data");
      return { regularHours: 0, standbyHours: 0, total: 0 };
    }

    // Calculate service hours
    const serviceTotal = day.services.reduce((sum, service, index) => {
      console.log(`Calculating service ${index + 1}:`, service);
      const serviceHours = calculateTotalTime(
        service.startTime,
        service.endTime
      );
      return sum + serviceHours;
    }, 0);

    console.log("Total service hours:", serviceTotal);

    // Calculate lunch duration
    let lunchDuration = 0;
    if (day.lunchStartTime && day.lunchEndTime) {
      console.log("Calculating lunch duration");

      const lunchStart = moment(day.lunchStartTime, ["h:mm A", "HH:mm"]);
      const lunchEnd = moment(day.lunchEndTime, ["h:mm A", "HH:mm"]);

      // Check if lunch overlaps with any service
      const lunchOverlaps = day.services.some((service) => {
        const serviceStart = moment(service.startTime, ["h:mm A", "HH:mm"]);
        const serviceEnd = moment(service.endTime, ["h:mm A", "HH:mm"]);
        return (
          lunchStart.isBetween(serviceStart, serviceEnd, null, "[]") ||
          lunchEnd.isBetween(serviceStart, serviceEnd, null, "[]") ||
          (lunchStart.isSameOrBefore(serviceStart) &&
            lunchEnd.isSameOrAfter(serviceEnd))
        );
      });

      if (lunchOverlaps) {
        lunchDuration = calculateTotalTime(
          day.lunchStartTime,
          day.lunchEndTime
        );
        console.log("Lunch duration (overlapping):", lunchDuration);
      } else {
        console.log("Lunch break between services - not subtracting");
      }
    }

    // Calculate final hours
    if (day.isStandBy) {
      const regularHours = serviceTotal;
      const standbyHours = Math.max(24 - regularHours, 0);
      const total = regularHours + standbyHours;

      console.log("Standby calculation:", {
        regularHours,
        standbyHours,
        total,
      });

      return { regularHours, standbyHours, total };
    } else {
      const regularHours = Math.max(serviceTotal - lunchDuration, 0);

      console.log("Regular day calculation:", {
        serviceTotal,
        lunchDuration,
        regularHours,
      });

      return { regularHours, standbyHours: 0, total: regularHours };
    }
  };

  // Calculate weekly total whenever timesheetData changes
  useEffect(() => {
    const total = timesheetData.reduce((weekSum, day) => {
      if (!day) return weekSum;
      const { regularHours, standbyHours } = calculateDailyTotal(day);
      return weekSum + regularHours + standbyHours;
    }, 0);
    const roundedTotal = parseFloat(total.toFixed(2));
    setWeeklyTotal(roundedTotal);
    onWeeklyTotalChange(roundedTotal);
  }, [timesheetData, onWeeklyTotalChange]);

  // Render each day's card
  const renderDayCard = (day, index) => {
    // Add defensive checks at the start
    if (!timesheetData) {
      console.error("timesheetData is undefined");
      return null;
    }

    // Get saved data for this specific date
    const dayDate = day.format("YYYY-MM-DD");

    // Find the matching day data by comparing dates
    const dayData = timesheetData.find(
      (d) => moment(d.date).format("YYYY-MM-DD") === dayDate
    ) || {
      date: dayDate,
      services: [],
      isStandBy: false,
      lunchStartTime: "",
      lunchEndTime: "",
    };

    console.log("Rendering day card:", {
      index,
      dayDate,
      matchedDate: dayData.date,
      services: dayData.services,
      timesheetDataLength: timesheetData.length,
    });

    const isSaved = Array.isArray(savedDays) && savedDays[index];
    const dailyTotal = calculateDailyTotal(dayData);

    return (
      <View
        key={index}
        style={[
          UserStyles.dayCard,
          colorScheme === "dark"
            ? UserStyles.dayCardDark
            : UserStyles.dayCardLight,
          day.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
            UserStyles.currentDayCard,
        ]}
      >
        {isSaved && (
          <View style={UserStyles.savedBadge}>
            <Text style={UserStyles.savedBadgeText}>Saved</Text>
          </View>
        )}
        <Text
          style={[
            UserStyles.dayText,
            colorScheme === "dark"
              ? UserStyles.dayTextDark
              : UserStyles.dayTextLight,
          ]}
        >
          {day.format("MM/DD/YY dddd")}
        </Text>

        {/* Stand-by Switch */}
        <View style={UserStyles.standBySwitchContainer}>
          <Text
            style={[
              UserStyles.standByLabel,
              colorScheme === "dark"
                ? UserStyles.standByLabelDark
                : UserStyles.standByLabelLight,
            ]}
          >
            Stand-by Day:
          </Text>
          <Switch
            value={dayData.isStandBy}
            onValueChange={(value) =>
              updateSpecialTimesheetData(index, null, "isStandBy", value)
            }
            disabled={isSubmitted}
          />
        </View>

        {/* Lunch Times */}
        {!dayData.isStandBy && (
          <View
            style={[
              UserStyles.lunchContainer,
              isMobile && { flexDirection: "column" },
            ]}
          >
            <View
              style={[UserStyles.lunchInput, isMobile && { marginBottom: 8 }]}
            >
              <TimeInput
                label="Lunch Start"
                time={dayData.lunchStartTime}
                onChange={(time) =>
                  updateSpecialTimesheetData(
                    index,
                    null,
                    "lunchStartTime",
                    time
                  )
                }
                disabled={isSubmitted}
                colorScheme={colorScheme}
              />
            </View>
            <View style={UserStyles.lunchInput}>
              <TimeInput
                label="Lunch End"
                time={dayData.lunchEndTime}
                onChange={(time) =>
                  updateSpecialTimesheetData(index, null, "lunchEndTime", time)
                }
                disabled={isSubmitted}
                colorScheme={colorScheme}
              />
            </View>
          </View>
        )}

        {/* Services */}
        <Text
          style={[
            UserStyles.serviceCountText,
            colorScheme === "dark"
              ? UserStyles.serviceCountTextDark
              : UserStyles.serviceCountTextLight,
          ]}
        >
          Services Entered:{" "}
          {Array.isArray(dayData.services) ? dayData.services.length : 0}
        </Text>

        {Array.isArray(dayData.services) &&
          dayData.services.map((service, serviceIndex) => (
            <View key={serviceIndex} style={UserStyles.serviceRow}>
              <TextInput
                style={[
                  UserStyles.serviceInput,
                  colorScheme === "dark"
                    ? UserStyles.serviceInputDark
                    : UserStyles.serviceInputLight,
                ]}
                value={service.service}
                onChangeText={(text) =>
                  updateSpecialTimesheetData(
                    index,
                    serviceIndex,
                    "service",
                    text
                  )
                }
                placeholder="Service Provided"
                placeholderTextColor={colorScheme === "dark" ? "#888" : "#888"}
                editable={!isSubmitted}
              />
              <View
                style={[
                  UserStyles.timeInputContainer,
                  isMobile && { flexDirection: "column" },
                ]}
              >
                <TimeInput
                  label="Start"
                  time={service.startTime}
                  onChange={(time) =>
                    updateSpecialTimesheetData(
                      index,
                      serviceIndex,
                      "startTime",
                      time
                    )
                  }
                  colorScheme={colorScheme}
                  disabled={isSubmitted}
                />
                <TimeInput
                  label="End"
                  time={service.endTime}
                  onChange={(time) =>
                    updateSpecialTimesheetData(
                      index,
                      serviceIndex,
                      "endTime",
                      time
                    )
                  }
                  colorScheme={colorScheme}
                  disabled={isSubmitted}
                />
                <View
                  style={[
                    UserStyles.totalTimeCell,
                    colorScheme === "dark"
                      ? UserStyles.totalTimeCellDark
                      : UserStyles.totalTimeCellLight,
                    isMobile && { width: "100%", marginLeft: 0, marginTop: 8 },
                  ]}
                >
                  <Text style={UserStyles.totalTimeLabel}>Total</Text>
                  <Text style={UserStyles.totalTimeValue}>
                    {service.totalTime ? service.totalTime.toFixed(2) : "0.00"}
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                style={[
                  UserStyles.removeServiceButton,
                  isSubmitted && UserStyles.disabledButton,
                ]}
                onPress={() => removeServiceFromDay(index, serviceIndex)}
                disabled={isSubmitted}
              >
                <Text style={UserStyles.buttonText}>Remove Service</Text>
              </TouchableOpacity>
            </View>
          ))}

        {/* Add Service Button */}
        <TouchableOpacity
          style={[
            UserStyles.addServiceButton,
            isSubmitted && UserStyles.disabledButton,
          ]}
          onPress={() => {
            console.log("Adding service to day:", index);
            addServiceToDay(index);
          }}
          disabled={isSubmitted}
        >
          <Text style={UserStyles.buttonText}>+ Add Service</Text>
        </TouchableOpacity>

        {/* Save Day Button */}
        <TouchableOpacity
          style={[
            UserStyles.saveButton,
            isSaved ? UserStyles.updateButton : null,
            isSubmitted && UserStyles.disabledButton,
          ]}
          onPress={() => onSaveDay(index, dayData)}
          disabled={isSubmitted}
        >
          <Text
            style={[
              UserStyles.buttonText,
              isSaved ? UserStyles.updateButtonText : null,
              isSubmitted && UserStyles.disabledButtonText,
            ]}
          >
            {isSaved ? "Update Day" : "Save Day"}
          </Text>
        </TouchableOpacity>

        {/* Daily Totals */}
        <View style={UserStyles.totalRow}>
          <Text
            style={[
              UserStyles.totalLabel,
              colorScheme === "dark"
                ? UserStyles.totalLabelDark
                : UserStyles.totalLabelLight,
            ]}
          >
            Regular Hours:
          </Text>
          <Text
            style={[
              UserStyles.totalTime,
              colorScheme === "dark"
                ? UserStyles.totalTimeDark
                : UserStyles.totalTimeLight,
            ]}
          >
            {dailyTotal.regularHours.toFixed(2)} hours
          </Text>
        </View>

        {dayData.isStandBy && (
          <View style={UserStyles.totalRow}>
            <Text
              style={[
                UserStyles.totalLabel,
                colorScheme === "dark"
                  ? UserStyles.totalLabelDark
                  : UserStyles.totalLabelLight,
              ]}
            >
              Stand-by Hours:
            </Text>
            <Text
              style={[
                UserStyles.totalTime,
                colorScheme === "dark"
                  ? UserStyles.totalTimeDark
                  : UserStyles.totalTimeLight,
              ]}
            >
              {dailyTotal.standbyHours.toFixed(2)} hours
            </Text>
          </View>
        )}
      </View>
    );
  };

  return (
    <ScrollView>
      {currentWeek.map((day, index) => renderDayCard(day, index))}
      {/* Weekly Total Hours Display */}
      <View
        style={[
          UserStyles.weeklyTotalContainer,
          colorScheme === "dark" ? UserStyles.weeklyTotalContainerDark : null,
        ]}
      >
        <Text
          style={[
            UserStyles.weeklyTotalText,
            colorScheme === "dark" ? UserStyles.weeklyTotalTextDark : null,
          ]}
        >
          Weekly Total Hours: {weeklyTotal.toFixed(2)} hours
        </Text>
      </View>
    </ScrollView>
  );
};

SpecialFormatTimesheet.propTypes = {
  currentWeek: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorScheme: PropTypes.oneOf(["light", "dark"]).isRequired,
  onSaveDay: PropTypes.func.isRequired,
  savedDays: PropTypes.arrayOf(PropTypes.bool).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  timesheetData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          service: PropTypes.string.isRequired,
          startTime: PropTypes.string.isRequired,
          endTime: PropTypes.string.isRequired,
        })
      ).isRequired,
      isStandBy: PropTypes.bool.isRequired,
      lunchStartTime: PropTypes.string,
      lunchEndTime: PropTypes.string,
    })
  ).isRequired,
  updateSpecialTimesheetData: PropTypes.func.isRequired,
  addServiceToDay: PropTypes.func.isRequired,
  removeServiceFromDay: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  employeeName: PropTypes.string.isRequired,
  prepareDayData: PropTypes.func.isRequired,
  onWeeklyTotalChange: PropTypes.func.isRequired,
};

export default SpecialFormatTimesheet;
