import React, { useState, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  ImageBackground,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { FIREBASE_AUTH, FIREBASE_DB } from "../../FirebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { BlurView } from "expo-blur";
import CustomAlert from "./CustomAlert"; // Make sure to import the CustomAlert component

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticating, setAuthenticating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const auth = FIREBASE_AUTH;

  const showAlert = (title, message) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertVisible(true);
  };

  const setRoleForUser = async (userId, role, email, name) => {
    try {
      const userDocRef = doc(FIREBASE_DB, "users", userId);
      await setDoc(userDocRef, { role, email, name }, { merge: true });
    } catch (error) {
      console.error("Error setting role, email, and name for user:", error);
      throw error;
    }
  };

  const handleForgotPassword = () => {
    if (!email) {
      showAlert("Error", "Please enter an email address");
      return;
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        showAlert("Success", "Password reset email sent!");
      })
      .catch((error) => {
        console.error(error);
        showAlert("Error", error.message);
      });
  };

  const handleLogin = async () => {
    if (!email || !password) {
      showAlert("Error", "Please enter an email and password");
      return;
    }
    setAuthenticating(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const role = await getRoleForUser(FIREBASE_AUTH.currentUser.uid);
      console.log("User role: ", role);
    } catch (e) {
      console.error(e);
      showAlert("Error", e.message);
    } finally {
      setAuthenticating(false);
    }
  };

  const getRoleForUser = async (userId) => {
    try {
      const userDocRef = doc(FIREBASE_DB, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return userDoc.data().role;
      } else {
        throw new Error("No such user!");
      }
    } catch (error) {
      console.error("Error getting role for user:", error);
      throw error;
    }
  };

  const InputField = useCallback(
    ({
      placeholder,
      value,
      onChangeText,
      secureTextEntry,
      togglePasswordVisibility,
      icon,
      onSubmitEditing,
      returnKeyType,
    }) => {
      const [isFocused, setIsFocused] = useState(false);

      return (
        <View
          style={[
            styles.inputContainer,
            isFocused && styles.inputContainerFocused,
          ]}
        >
          <Ionicons
            name={icon}
            size={24}
            color="#fff"
            style={styles.inputIcon}
          />
          <TextInput
            placeholder={placeholder}
            value={value}
            onChangeText={onChangeText}
            secureTextEntry={secureTextEntry}
            style={styles.input}
            placeholderTextColor="rgba(255, 255, 255, 0.7)"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onSubmitEditing={onSubmitEditing}
            returnKeyType={returnKeyType}
          />
          {togglePasswordVisibility && (
            <TouchableOpacity
              onPress={togglePasswordVisibility}
              style={styles.eyeIcon}
            >
              <Ionicons
                name={secureTextEntry ? "eye-off" : "eye"}
                size={24}
                color="#fff"
              />
            </TouchableOpacity>
          )}
        </View>
      );
    },
    []
  );

  return (
    <ImageBackground
      source={require("../../assets/login.png")}
      style={styles.backgroundImage}
    >
      <BlurView intensity={100} style={StyleSheet.absoluteFill} tint="dark" />
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.flex}
      >
        <ScrollView contentContainerStyle={styles.container}>
          <Text style={styles.title}>Welcome Back</Text>
          <Text style={styles.subtitle}>Sign in to continue</Text>

          <InputField
            placeholder="Email"
            value={email}
            onChangeText={setEmail}
            icon="mail-outline"
            returnKeyType="next"
            onSubmitEditing={() => {
              /* Focus password input */
            }}
          />

          <InputField
            placeholder="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry={!showPassword}
            togglePasswordVisibility={() => setShowPassword(!showPassword)}
            icon="lock-closed-outline"
            returnKeyType="done"
            onSubmitEditing={handleLogin}
          />

          <TouchableOpacity
            style={styles.button}
            onPress={handleLogin}
            disabled={authenticating}
          >
            {authenticating ? (
              <ActivityIndicator color="#fff" />
            ) : (
              <Text style={styles.buttonText}>Sign In</Text>
            )}
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.forgotButton}
            onPress={handleForgotPassword}
          >
            <Text style={styles.forgotText}>Forgot Password?</Text>
          </TouchableOpacity>

          <CustomAlert
            visible={alertVisible}
            title={alertTitle}
            message={alertMessage}
            onClose={() => setAlertVisible(false)}
          />
        </ScrollView>
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.7)",
    marginBottom: 30,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    maxWidth: 400,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "rgba(255, 255, 255, 0.5)",
  },
  inputContainerFocused: {
    borderBottomColor: "#007bff",
  },
  inputIcon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    paddingVertical: 10,
    color: "#fff",
    fontSize: 16,
  },
  eyeIcon: {
    padding: 10,
  },
  button: {
    width: "100%",
    maxWidth: 400,
    backgroundColor: "#007bff",
    borderRadius: 25,
    paddingVertical: 15,
    marginTop: 20,
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
  },
  toggleButton: {
    marginTop: 20,
  },
  toggleButtonText: {
    color: "#fff",
    fontSize: 14,
  },
  forgotButton: {
    marginTop: 15,
  },
  forgotText: {
    color: "#fff",
    fontSize: 14,
  },
  error: {
    color: "#ff6b6b",
    marginTop: 10,
  },
  alertContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
  },
  alertText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
  },
});

export default Login;
