import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  StatusBar,
  Alert,
  Platform,
  Switch,
  useColorScheme as useDeviceColorScheme,
  ActivityIndicator,
} from "react-native";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import { Feather, AntDesign } from "@expo/vector-icons";
import { getDocs, query, collection, where } from "firebase/firestore";
import { FIREBASE_DB, FIREBASE_AUTH } from "../../FirebaseConfig";
import moment from "moment-timezone";
import CustomAlert from "./CustomAlert";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

// Add this function before the EmployeeProfile component
const generatePassword = () => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
  let password = "";
  for (let i = 0; i < 12; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

const BackButton = ({ color }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const EmployeeProfile = ({ route }) => {
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);

  if (!route || !route.params) {
    return <Text>Error: No route parameters provided.</Text>;
  }

  const { employee } = route.params;
  const [editedEmployee, setEditedEmployee] = useState(employee);
  const [isReminderEnabled, setIsReminderEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unregisteredUsers, setUnregisteredUsers] = useState([]);
  const [isCheckingRegistration, setIsCheckingRegistration] = useState(true);
  const [alertConfig, setAlertConfig] = useState({
    visible: false,
    title: "",
    message: "",
    onConfirm: null,
    showCancel: false,
  });

  useEffect(() => {
    if (!editedEmployee.supervisors) {
      setEditedEmployee((prev) => ({ ...prev, supervisors: [] }));
    }
    fetchReminderEmailStatus();
    checkRegistrationStatus();
  }, []);

  const fetchReminderEmailStatus = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/employees/${editedEmployee.employeeId}/reminder-emails`
      );
      setIsReminderEnabled(response.data.reminderEmailsEnabled);
    } catch (error) {
      console.error("Error fetching reminder email status:", error);
      showAlert(
        "Error",
        "Failed to fetch reminder email status. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggleReminderEmails = async (value) => {
    setIsLoading(true);
    try {
      await axios.put(
        `https://sbg-backend.onrender.com/employees/${editedEmployee.employeeId}/reminder-emails`,
        { enabled: value }
      );
      setIsReminderEnabled(value);
      showAlert(
        "Success",
        `Reminder emails have been ${value ? "enabled" : "disabled"}.`
      );
    } catch (error) {
      console.error("Error updating reminder email status:", error);
      showAlert(
        "Error",
        "Failed to update reminder email status. Please try again later."
      );
      setIsReminderEnabled(!value);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const handleEdit = (key, value) => {
    setEditedEmployee((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSupervisorEdit = (index, field, value) => {
    setEditedEmployee((prevState) => {
      const newSupervisors = [...prevState.supervisors];
      newSupervisors[index] = { ...newSupervisors[index], [field]: value };
      return { ...prevState, supervisors: newSupervisors };
    });
  };

  const addSupervisor = () => {
    setEditedEmployee((prevState) => ({
      ...prevState,
      supervisors: [...prevState.supervisors, { id: "", email: "" }],
    }));
  };

  const removeSupervisor = (index) => {
    setEditedEmployee((prevState) => ({
      ...prevState,
      supervisors: prevState.supervisors.filter((_, i) => i !== index),
    }));
  };

  const saveChanges = () => {
    // Safely filter out empty supervisors
    const filteredSupervisors = (editedEmployee.supervisors || []).filter(
      (supervisor) =>
        supervisor &&
        supervisor.id &&
        supervisor.email &&
        supervisor.id.trim() !== "" &&
        supervisor.email.trim() !== ""
    );

    const updatedEmployee = {
      ...editedEmployee,
      department: editedEmployee.department || "",
      supervisorId:
        editedEmployee.supervisorId || filteredSupervisors[0]?.id || "",
      supervisors: filteredSupervisors,
    };

    axios
      .put(
        `https://sbg-backend.onrender.com/editEmployee/${editedEmployee.employeeId}`,
        updatedEmployee
      )
      .then(() => {
        showAlert("Success", "Employee updated successfully", () => {
          navigation.navigate("Employee Database");
        });
      })
      .catch((error) => {
        console.error("Error updating employee:", error);
        showAlert("Error", "Failed to update employee");
      });
  };

  const deleteEmployee = () => {
    showAlert(
      "Confirm Deletion",
      "Are you sure you want to delete this employee?",
      performDelete,
      true
    );
  };

  const checkRegistrationStatus = async () => {
    setIsCheckingRegistration(true);
    try {
      const unregistered = [];
      const employeeEmail = editedEmployee.email;

      // Check employee registration
      const employeeSnapshot = await getDocs(
        query(
          collection(FIREBASE_DB, "users"),
          where("email", "==", employeeEmail)
        )
      );

      if (employeeSnapshot.empty) {
        unregistered.push({
          email: employeeEmail,
          name: editedEmployee.employeeName,
          type: "employee",
        });
      }

      // Check supervisors registration
      for (const supervisor of editedEmployee.supervisors) {
        if (supervisor.email) {
          const supervisorSnapshot = await getDocs(
            query(
              collection(FIREBASE_DB, "users"),
              where("email", "==", supervisor.email)
            )
          );

          if (supervisorSnapshot.empty) {
            unregistered.push({
              email: supervisor.email,
              name: supervisor.id,
              type: "supervisor",
            });
          }
        }
      }

      setUnregisteredUsers(unregistered);
    } catch (error) {
      console.error("Error checking registration status:", error);
    } finally {
      setIsCheckingRegistration(false);
    }
  };

  const handleRegisterUser = async (user) => {
    try {
      setIsLoading(true);
      const generatedPassword = generatePassword();

      // Make API call to your backend to create user
      const response = await axios.post(
        "https://sbg-backend.onrender.com/createUser",
        {
          email: user.email,
          password: generatedPassword,
          name: user.name,
          role: user.type,
        }
      );

      if (response.data.success) {
        showAlert(
          "Registration Success",
          `User registered successfully!\nCredentials:\nEmail: ${user.email}\nPassword: ${generatedPassword}`,
          async () => {
            try {
              await Clipboard.setStringAsync(generatedPassword);
              showAlert("Success", "Password copied to clipboard!");
              checkRegistrationStatus();
            } catch (error) {
              console.error("Failed to copy password:", error);
            }
          },
          true
        );
      }
    } catch (error) {
      console.error("Error registering user:", error);
      let errorMessage = "Failed to register user. Please try again.";
      if (error.response?.data?.code === "auth/email-already-in-use") {
        errorMessage = "This email is already registered.";
      }
      showAlert("Registration Failed", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const renderRegistrationButtons = () => {
    if (isCheckingRegistration) {
      return <ActivityIndicator size="small" color="#007AFF" />;
    }

    if (unregisteredUsers.length === 0) {
      return null;
    }

    return (
      <View style={styles.registrationContainer}>
        <Text
          style={[
            styles.registrationTitle,
            colorScheme === "dark" && styles.registrationTitleDark,
          ]}
        >
          Unregistered Users
        </Text>
        {unregisteredUsers.map((user, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.registrationButton,
              colorScheme === "dark" && styles.registrationButtonDark,
            ]}
            onPress={() => handleRegisterUser(user)}
          >
            <Text style={styles.registrationButtonText}>
              Register {user.email}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  const showAlert = (title, message, onConfirm = null, showCancel = false) => {
    setAlertConfig({
      visible: true,
      title,
      message,
      onConfirm,
      showCancel,
    });
  };

  const performDelete = () => {
    axios
      .delete(
        `https://sbg-backend.onrender.com/deleteEmployee/${editedEmployee.employeeId}`
      )
      .then((response) => {
        console.log("Employee deleted successfully");
        if (Platform.OS === "web") {
          window.alert("The employee has been deleted successfully.");
        } else {
          showAlert(
            "Employee Deleted",
            "The employee has been deleted successfully.",
            [{ text: "OK", onPress: () => navigation.navigate("Employees") }],
            { cancelable: false }
          );
        }
        navigation.navigate("Employees");
      })
      .catch((error) => {
        console.error("Error deleting employee:", error.response?.data);
      });
  };

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
      />

      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#000000"} />
        <Text
          style={[styles.title, colorScheme === "dark" && styles.titleDark]}
        >
          Employee Profile
        </Text>
        <TouchableOpacity onPress={toggleColorScheme}>
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
          />
        </TouchableOpacity>
      </View>

      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={{ alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.formContainer}>
          <View style={styles.formGrid}>
            {Object.entries(editedEmployee)
              .filter(([key]) => !["_id", "__v", "supervisors"].includes(key))
              .map(([key, value]) => (
                <View key={key} style={styles.formField}>
                  <Text
                    style={[
                      styles.label,
                      colorScheme === "dark" && styles.labelDark,
                    ]}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}:
                  </Text>
                  <TextInput
                    style={[
                      styles.input,
                      colorScheme === "dark" && styles.inputDark,
                    ]}
                    value={
                      key === "createdAt"
                        ? moment(value)
                            .tz("America/Phoenix")
                            .format("MMMM D, YYYY h:mm A [MST]")
                        : value?.toString() || ""
                    }
                    onChangeText={(text) => handleEdit(key, text)}
                    editable={!["employeeId", "createdAt"].includes(key)}
                    placeholderTextColor={
                      colorScheme === "dark" ? "#666" : "#999"
                    }
                  />
                </View>
              ))}
          </View>

          <View>
            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Department:
            </Text>
            <TextInput
              style={[styles.input, colorScheme === "dark" && styles.inputDark]}
              value={editedEmployee.department || ""}
              onChangeText={(text) => handleEdit("department", text)}
              placeholder="Enter department"
              placeholderTextColor={colorScheme === "dark" ? "#666" : "#999"}
            />
          </View>

          {/* Supervisors Section */}
          <View style={styles.supervisorsContainer}>
            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Supervisors:
            </Text>
            {editedEmployee.supervisors?.map((supervisor, index) => (
              <View key={index} style={styles.supervisorRow}>
                <View style={styles.supervisorInput}>
                  <TextInput
                    style={[
                      styles.input,
                      colorScheme === "dark" && styles.inputDark,
                    ]}
                    value={supervisor.id}
                    onChangeText={(text) =>
                      handleSupervisorEdit(index, "id", text)
                    }
                    placeholder="Supervisor ID"
                    placeholderTextColor={
                      colorScheme === "dark" ? "#666" : "#999"
                    }
                  />
                </View>
                <View style={styles.supervisorInput}>
                  <TextInput
                    style={[
                      styles.input,
                      colorScheme === "dark" && styles.inputDark,
                    ]}
                    value={supervisor.email}
                    onChangeText={(text) =>
                      handleSupervisorEdit(index, "email", text)
                    }
                    placeholder="Supervisor Email"
                    placeholderTextColor={
                      colorScheme === "dark" ? "#666" : "#999"
                    }
                  />
                </View>
                <TouchableOpacity
                  onPress={() => removeSupervisor(index)}
                  style={styles.removeSupervisorButton}
                >
                  <AntDesign
                    name="minuscircleo"
                    size={24}
                    color={colorScheme === "dark" ? "#FFFFFF" : "#FF3B30"}
                  />
                </TouchableOpacity>
              </View>
            ))}

            <TouchableOpacity
              onPress={addSupervisor}
              style={styles.addSupervisorButton}
            >
              <AntDesign
                name="pluscircleo"
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
              />
              <Text
                style={[
                  styles.addSupervisorText,
                  colorScheme === "dark" && styles.addSupervisorTextDark,
                ]}
              >
                Add Supervisor
              </Text>
            </TouchableOpacity>
          </View>

          {/* Reminder Toggle */}
          <View
            style={[
              styles.reminderToggleContainer,
              colorScheme === "dark" && styles.reminderToggleContainerDark,
            ]}
          >
            <Text
              style={[
                styles.reminderToggleText,
                colorScheme === "dark" && styles.reminderToggleTextDark,
              ]}
            >
              {isReminderEnabled
                ? "Disable Reminder Emails"
                : "Enable Reminder Emails"}
            </Text>
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={isReminderEnabled ? "#f5dd4b" : "#f4f3f4"}
              onValueChange={toggleReminderEmails}
              value={isReminderEnabled}
              disabled={isLoading}
            />
          </View>

          {/* Registration Section */}
          {unregisteredUsers.length > 0 && (
            <View style={styles.registrationContainer}>
              <Text
                style={[
                  styles.registrationTitle,
                  colorScheme === "dark" && styles.registrationTitleDark,
                ]}
              >
                Unregistered Users
              </Text>
              {unregisteredUsers.map((user, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.registrationButton,
                    colorScheme === "dark" && styles.registrationButtonDark,
                  ]}
                  onPress={() => handleRegisterUser(user)}
                >
                  <Text style={styles.registrationButtonText}>
                    Register {user.email}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          )}

          {/* Action Buttons */}
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[
                styles.saveButton,
                colorScheme === "dark" && styles.saveButtonDark,
              ]}
              onPress={saveChanges}
            >
              <Text style={styles.buttonText}>Save Changes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.deleteButton,
                colorScheme === "dark" && styles.deleteButtonDark,
              ]}
              onPress={deleteEmployee}
            >
              <Text style={styles.buttonText}>Delete Employee</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>

      <CustomAlert
        visible={alertConfig.visible}
        title={alertConfig.title}
        message={alertConfig.message}
        onConfirm={alertConfig.onConfirm}
        showCancel={alertConfig.showCancel}
        onClose={() => setAlertConfig((prev) => ({ ...prev, visible: false }))}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
    width: "100%",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
    width: "100%",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  backButton: {
    padding: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  titleDark: {
    color: "#FFFFFF",
  },
  formContainer: {
    padding: 16,
    width: "100%",
    maxWidth: Platform.OS === "web" ? 800 : "100%",
  },
  label: {
    fontSize: 16,
    marginBottom: 8,
    color: "#000000",
  },
  labelDark: {
    color: "#FFFFFF",
  },
  input: {
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 8,
    padding: 12,
    marginBottom: 16,
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  inputDark: {
    borderColor: "#333",
    backgroundColor: "#1E1E1E",
    color: "#FFFFFF",
  },
  supervisorsContainer: {
    marginBottom: 16,
  },
  supervisorRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    gap: 8,
    flexWrap: "wrap",
  },
  supervisorInput: {
    flex: 1,
    marginRight: 8,
    minWidth: Platform.OS === "web" ? 200 : "100%",
  },
  removeSupervisorButton: {
    padding: 8,
  },
  addSupervisorButton: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
  },
  addSupervisorText: {
    marginLeft: 8,
    fontSize: 16,
    color: "#007AFF",
  },
  addSupervisorTextDark: {
    color: "#FFFFFF",
  },
  reminderToggleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginTop: 20,
    width: "100%",
    maxWidth: Platform.OS === "web" ? 500 : "100%",
  },
  reminderToggleContainerDark: {
    backgroundColor: "#333",
    shadowColor: "#FFF",
  },
  reminderToggleText: {
    fontSize: 16,
    fontWeight: "500",
    color: "#4A4A4A",
  },
  reminderToggleTextDark: {
    color: "#FFFFFF",
  },
  registrationContainer: {
    marginTop: 20,
    padding: 15,
    backgroundColor: "#f8f9fa",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#dee2e6",
  },
  registrationTitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 10,
    color: "#212529",
  },
  registrationTitleDark: {
    color: "#f8f9fa",
  },
  registrationButton: {
    backgroundColor: "#28a745",
    padding: 12,
    borderRadius: 6,
    marginVertical: 5,
  },
  registrationButtonDark: {
    backgroundColor: "#198754",
  },
  registrationButtonText: {
    color: "#ffffff",
    fontSize: 14,
    fontWeight: "500",
    textAlign: "center",
  },
  saveButton: {
    backgroundColor: "#007AFF",
    padding: 16,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 16,
    width: "100%",
    maxWidth: 200,
    alignSelf: "center",
  },
  saveButtonDark: {
    backgroundColor: "#0A84FF",
  },
  deleteButton: {
    backgroundColor: "#FF3B30",
    padding: 16,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 16,
    width: "100%",
    maxWidth: 200,
    alignSelf: "center",
  },
  deleteButtonDark: {
    backgroundColor: "#FF453A",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "600",
  },
  formGrid: {
    flexDirection: Platform.OS === "web" ? "row" : "column",
    flexWrap: "wrap",
    gap: 16,
    width: "100%",
  },
  formField: {
    flex: Platform.OS === "web" ? 1 : 0,
    width: Platform.OS === "web" ? "48%" : "100%",
    minWidth: Platform.OS === "web" ? 300 : "auto",
  },
  scrollView: {
    flex: 1,
    width: "100%",
    paddingHorizontal: Platform.OS === "web" ? 0 : 16,
  },
});

export default EmployeeProfile;
