// TimesheetTests.js
import moment from "moment-timezone";

const TimesheetTests = {
  testDateConversion() {
    console.log("\n=== Running Date Conversion Tests ===");
    const testCases = [
      { input: "9:00 AM", expected: "09:00" },
      { input: "2:30 PM", expected: "14:30" },
      { input: "12:00 AM", expected: "00:00" }, // Midnight
      { input: "12:00 PM", expected: "12:00" }, // Noon
      { input: "11:59 PM", expected: "23:59" }, // End of day
    ];

    testCases.forEach(({ input, expected }) => {
      const result = this.convertTime(input);
      const passed = result === expected;
      console.log(
        `${passed ? "✓" : "✗"} ${input} → ${result} (Expected: ${expected})`
      );
    });
  },

  testWeekGeneration() {
    console.log("\n=== Running Week Generation Tests ===");
    // Test regular week
    const testDate = moment("2024-04-15"); // Monday
    const week = this.generateWeek(testDate);
    console.log("Regular week:", week);

    // Test week crossing month boundary
    const monthEnd = moment("2024-04-29"); // Monday before May
    const crossMonthWeek = this.generateWeek(monthEnd);
    console.log("Week crossing month:", crossMonthWeek);
  },

  testServiceCalculations() {
    console.log("\n=== Running Service Calculations Tests ===");

    const testCases = [
      {
        desc: "Regular 8-hour day",
        service: { startTime: "9:00 AM", endTime: "5:00 PM" },
        expected: 8,
      },
      {
        desc: "Midnight crossover",
        service: { startTime: "10:00 PM", endTime: "2:00 AM" },
        expected: 4,
      },
      {
        desc: "Short service",
        service: { startTime: "2:00 PM", endTime: "3:30 PM" },
        expected: 1.5,
      },
    ];

    testCases.forEach(({ desc, service, expected }) => {
      const result = this.calculateServiceTime(service);
      console.log(`${desc}: ${result} hours (Expected: ${expected})`);
    });
  },

  testMultipleServices() {
    console.log("\n=== Running Multiple Services Tests ===");
    const dayWithMultipleServices = {
      date: "2024-04-15",
      services: [
        { startTime: "9:00 AM", endTime: "11:00 AM" },
        { startTime: "1:00 PM", endTime: "3:00 PM" },
        { startTime: "4:00 PM", endTime: "6:00 PM" },
      ],
    };

    const total = this.calculateTotalDayHours(dayWithMultipleServices);
    console.log("Multiple services total:", total, "hours");
  },

  testLunchBreakCalculations() {
    console.log("\n=== Running Lunch Break Tests ===");
    const testCases = [
      {
        desc: "Regular lunch hour",
        workDay: {
          startTime: "9:00 AM",
          endTime: "5:00 PM",
          lunchStart: "12:00 PM",
          lunchEnd: "1:00 PM",
        },
        expected: 7,
      },
      {
        desc: "30-minute lunch",
        workDay: {
          startTime: "8:00 AM",
          endTime: "4:00 PM",
          lunchStart: "12:00 PM",
          lunchEnd: "12:30 PM",
        },
        expected: 7.5,
      },
    ];

    testCases.forEach(({ desc, workDay, expected }) => {
      const total = this.calculateWorkDayWithLunch(workDay);
      console.log(`${desc}: ${total} hours (Expected: ${expected})`);
    });
  },

  testStandByCalculations() {
    console.log("\n=== Running Stand-By Tests ===");
    const standByDay = {
      date: "2024-04-15",
      services: [{ startTime: "9:00 AM", endTime: "5:00 PM" }],
      isStandBy: true,
      standByHours: 16, // 24 hours minus regular shift
    };

    const total = this.calculateStandByTotal(standByDay);
    console.log("Stand-by total:", total, "hours");
  },

  testWeeklyTotals() {
    console.log("\n=== Running Weekly Totals Tests ===");
    const weekData = {
      days: [
        { date: "2024-04-15", totalHours: 8 },
        { date: "2024-04-16", totalHours: 7.5 },
        { date: "2024-04-17", totalHours: 8 },
        { date: "2024-04-18", totalHours: 8 },
        { date: "2024-04-19", totalHours: 6 },
        { date: "2024-04-20", totalHours: 0 },
        { date: "2024-04-21", totalHours: 0 },
      ],
    };

    const total = this.calculateWeeklyTotal(weekData);
    console.log("Weekly total:", total, "hours");
  },

  testSpecialFormatCalculations() {
    console.log("\n=== Running Special Format Calculations Tests ===");

    // Test multiple services in a day
    const multipleServicesDay = {
      services: [
        { startTime: "9:00 AM", endTime: "11:00 AM", service: "Service 1" },
        { startTime: "2:00 PM", endTime: "4:00 PM", service: "Service 2" },
      ],
      lunchStartTime: "12:00 PM",
      lunchEndTime: "1:00 PM",
      isStandBy: false,
    };

    const totalHours = this.calculateTotalHours(multipleServicesDay);
    console.log(`Multiple services total: ${totalHours} hours (Expected: 4)`);

    // Test standby calculations
    const standbyDay = {
      services: [
        {
          startTime: "9:00 AM",
          endTime: "5:00 PM",
          service: "Regular Service",
        },
      ],
      lunchStartTime: "12:00 PM",
      lunchEndTime: "1:00 PM",
      isStandBy: true,
    };

    const standbyHours = this.calculateTotalHours(standbyDay);
    console.log(`Standby hours: ${standbyHours} hours (Expected: 17)`);
  },

  testTimeValidation() {
    console.log("\n=== Running Time Validation Tests ===");

    const testCases = [
      { time: "9:00 AM", expected: true },
      { time: "13:00", expected: true },
      { time: "25:00", expected: false },
      { time: "9:60 AM", expected: false },
      { time: "Invalid", expected: false },
    ];

    testCases.forEach(({ time, expected }) => {
      const isValid = this.validateTime(time);
      console.log(
        `Time ${time} validation: ${isValid} (Expected: ${expected})`
      );
    });
  },

  testSubmissionValidation() {
    console.log("\n=== Running Submission Validation Tests ===");

    const testCases = [
      {
        day: {
          services: [{ startTime: "9:00 AM", endTime: "5:00 PM" }],
          lunchStartTime: "12:00 PM",
          lunchEndTime: "1:00 PM",
        },
        expected: true,
      },
      {
        day: {
          services: [{ startTime: "9:00 AM" }], // Missing end time
        },
        expected: false,
      },
    ];

    testCases.forEach((testCase, index) => {
      const isValid = this.validateDaySubmission(testCase.day);
      console.log(
        `Test case ${index + 1}: ${isValid} (Expected: ${testCase.expected})`
      );
    });
  },

  // Helper methods
  convertTime(time) {
    return moment(time, "h:mm A").format("HH:mm");
  },

  generateWeek(startDate) {
    return Array.from({ length: 7 }, (_, i) =>
      moment(startDate).add(i, "days").format("YYYY-MM-DD")
    );
  },

  calculateServiceTime(service) {
    let start = moment(service.startTime, "h:mm A");
    let end = moment(service.endTime, "h:mm A");

    // Handle midnight crossover
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    return end.diff(start, "hours", true);
  },

  calculateTotalDayHours(day) {
    return day.services.reduce(
      (total, service) => total + this.calculateServiceTime(service),
      0
    );
  },

  calculateWorkDayWithLunch(workDay) {
    const workHours = this.calculateServiceTime({
      startTime: workDay.startTime,
      endTime: workDay.endTime,
    });

    const lunchHours = this.calculateServiceTime({
      startTime: workDay.lunchStart,
      endTime: workDay.lunchEnd,
    });

    return workHours - lunchHours;
  },

  calculateStandByTotal(day) {
    const regularHours = this.calculateTotalDayHours(day);
    return regularHours + (day.isStandBy ? day.standByHours : 0);
  },

  calculateWeeklyTotal(weekData) {
    return weekData.days.reduce((total, day) => total + day.totalHours, 0);
  },

  calculateTotalHours(day) {
    if (!day.services || !Array.isArray(day.services)) return 0;

    // Debug logging
    console.log("\nCalculating total hours for:", day);

    // Handle standby hours first
    if (day.isStandBy) {
      return 17;
    }

    // Sum up all service hours with proper time handling
    const serviceHours = day.services.reduce((total, service, index) => {
      console.log(`\nCalculating service ${index + 1}:`);

      if (!service.startTime || !service.endTime) return total;

      // Create full date-time objects for proper calculation
      const baseDate = moment().startOf("day");
      let start = moment(baseDate)
        .add(moment(service.startTime, "h:mm A").hours(), "hours")
        .add(moment(service.startTime, "h:mm A").minutes(), "minutes");
      let end = moment(baseDate)
        .add(moment(service.endTime, "h:mm A").hours(), "hours")
        .add(moment(service.endTime, "h:mm A").minutes(), "minutes");

      console.log("Start:", start.format("h:mm A"));
      console.log("End:", end.format("h:mm A"));

      // Calculate hours difference
      const hours = end.diff(start, "hours", true);
      console.log("Hours for this service:", hours);

      return total + hours;
    }, 0);

    console.log("\nTotal service hours:", serviceHours);

    // Don't subtract lunch if it's between services
    let lunchDuration = 0;
    if (day.lunchStartTime && day.lunchEndTime) {
      const lunchStart = moment(day.lunchStartTime, "h:mm A");
      const lunchEnd = moment(day.lunchEndTime, "h:mm A");

      // Check if lunch overlaps with any service
      const lunchOverlaps = day.services.some((service) => {
        const serviceStart = moment(service.startTime, "h:mm A");
        const serviceEnd = moment(service.endTime, "h:mm A");
        return (
          lunchStart.isBetween(serviceStart, serviceEnd) ||
          lunchEnd.isBetween(serviceStart, serviceEnd)
        );
      });

      if (lunchOverlaps) {
        lunchDuration = lunchEnd.diff(lunchStart, "hours", true);
        console.log("\nLunch duration (overlapping):", lunchDuration);
      } else {
        console.log("\nLunch break between services - not subtracting");
      }
    }

    const totalHours = Math.round((serviceHours - lunchDuration) * 100) / 100;
    console.log("\nFinal total hours:", totalHours);

    return totalHours;
  },

  calculateStandbyHours(day) {
    const regularHours = this.calculateTotalHours(day);
    return day.isStandBy ? 24 - regularHours : 0;
  },

  validateTime(time) {
    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
    const militaryRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(time) || militaryRegex.test(time);
  },

  validateDaySubmission(day) {
    if (!day.services || !Array.isArray(day.services)) return false;
    return day.services.every(
      (service) =>
        service.startTime &&
        service.endTime &&
        this.validateTime(service.startTime) &&
        this.validateTime(service.endTime)
    );
  },

  runAllTests() {
    try {
      this.testDateConversion();
      this.testSpecialFormatCalculations();
      this.testTimeValidation();
      this.testSubmissionValidation();
      console.log("\n✓ All tests completed successfully!");
    } catch (error) {
      console.error("\n✗ Test failed:", error);
    }
  },
};

export default TimesheetTests;
