import React, { useState, useRef } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { Feather } from "@expo/vector-icons";

const Chatbot = ({ colorScheme }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const flatListRef = useRef(null);

  const handleSend = () => {
    if (inputText.trim() === "") return;

    const userMessage = { id: Date.now(), text: inputText, user: true };
    setMessages((prevMessages) => [userMessage, ...prevMessages]);

    // Enhanced bot response logic
    let botResponse = "";
    const lowercaseInput = inputText.toLowerCase();

    if (lowercaseInput.includes("hello") || lowercaseInput.includes("hi")) {
      botResponse =
        "Hello! Welcome to the Scott Business Group Attendance App. How can I assist you today?";
    } else if (
      lowercaseInput.includes("login") ||
      lowercaseInput.includes("forgot password")
    ) {
      botResponse =
        'To log in, enter your registered email address. If you forgot your password, click the "Forgot Password" button and follow the link sent to your email from noreply@sbg-backend.firebaseapp.com to reset it.';
    } else if (
      lowercaseInput.includes("mark attendance") ||
      lowercaseInput.includes("timesheet")
    ) {
      botResponse =
        'To mark attendance, log in and click on your name to access the timesheet page. Enter your start and end times, and optionally lunch times, in the format "9:00 AM - 5:00 PM". Click "Save Day" to record your times. Remember to submit your timesheet by Monday before 12:00 noon. Hours over 40 per week will be paid as overtime (1.5x hourly rate).';
    } else if (lowercaseInput.includes("submit timesheet")) {
      botResponse =
        'Timesheets are due on Mondays before 12:00 noon. To submit your timesheet, review your recorded hours, agree to the Terms and Conditions, click "Submit Timesheet", confirm the weekly total hours, and sign your timesheet using the digital signature pad. Please check your entries for accuracy before submitting. Once submitted, you cannot modify the timesheet without supervisor approval.';
    } else if (
      lowercaseInput.includes("late submission") ||
      lowercaseInput.includes("missed deadline")
    ) {
      botResponse =
        "Timesheets not submitted on time may be processed in the subsequent pay period. Payroll staff will attempt to contact you if your timesheet is missing. It's important to submit your timesheet by Monday before 12:00 noon to ensure timely processing.";
    } else if (lowercaseInput.includes("overtime")) {
      botResponse =
        "Hours worked over 40 per week will be paid as overtime at 1.5 times your regular hourly rate. Make sure to accurately record all your hours worked.";
    } else if (lowercaseInput.includes("attendance history")) {
      botResponse =
        'You can view your recent attendance history below the timesheet entry area. For a full history, click the "View Attendance History" button on the timesheet page. This will show all your past attendance records, including work hours, vacation, sick time, and holiday hours.';
    } else if (
      lowercaseInput.includes("leave request") ||
      lowercaseInput.includes("personal leave") ||
      lowercaseInput.includes("time off")
    ) {
      botResponse =
        'To submit a personal leave or time off request, scroll to the bottom of the timesheet page and click the "Request Personal Leave" button. You can request the following types of leave:\n\n1. Earned Paid Sick Time\n2. Vacation\n3. Time off without Pay\n\nFill in the required details, including leave type, hours claimed, dates, and reason. You can track the status of your request in the Existing Leave Requests section. Remember to submit your request well in advance to allow for approval processing.';
    } else if (
      lowercaseInput.includes("sick time") ||
      lowercaseInput.includes("sick leave")
    ) {
      botResponse =
        'Earned Paid Sick Time is available for use when you or a family member are ill, injured, or have a medical appointment. To request sick time, use the "Request Personal Leave" form and select "Earned Paid Sick Time" as the leave type. Provide the reason and dates for your sick leave request.';
    } else if (
      lowercaseInput.includes("vacation") ||
      lowercaseInput.includes("holiday")
    ) {
      botResponse =
        'To request vacation time, use the "Request Personal Leave" form and select "Vacation" as the leave type. Specify the dates you plan to take off and any additional notes. It\'s recommended to submit vacation requests well in advance to ensure approval and proper scheduling.';
    } else if (
      lowercaseInput.includes("unpaid leave") ||
      lowercaseInput.includes("time off without pay")
    ) {
      botResponse =
        'If you need to take time off without pay, you can request this through the "Request Personal Leave" form. Select "Time off without Pay" as the leave type, specify the dates and reason for your request. Approval for unpaid leave is subject to company policy and supervisor discretion.';
    } else if (
      lowercaseInput.includes("leave balance") ||
      lowercaseInput.includes("remaining time off")
    ) {
      botResponse =
        'To check your leave balance or remaining time off, please refer to the "View Attendance History" page. This will show your accrued and used leave time. If you need more detailed information about your leave balance, please contact the HR department.';
    } else if (
      lowercaseInput.includes("support") ||
      lowercaseInput.includes("help")
    ) {
      botResponse =
        "For support with time off requests or any other attendance-related issues, please email sbg.software.dev@gmail.com or timesheets@scottbiz.net. They will be happy to assist you with any questions you may have.";
    } else {
      botResponse =
        "I'm sorry, I don't have specific information about that. For more details about time off, leave requests, or other attendance policies, please refer to the Employee User Guide or contact HR at sbg.software.dev@gmail.com or timesheets@scottbiz.net.";
    }

    const botMessage = { id: Date.now() + 1, text: botResponse, user: false };
    setMessages((prevMessages) => [botMessage, ...prevMessages]);

    setInputText("");

    // Scroll to the top of the FlatList
    flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
  };

  const handleKeyPress = (e) => {
    if (e.nativeEvent.key === "Enter") {
      handleSend();
    }
  };

  const renderMessage = ({ item }) => (
    <View
      style={[
        styles.messageBubble,
        item.user ? styles.userBubble : styles.botBubble,
      ]}
    >
      <Text
        style={[
          styles.messageText,
          item.user ? styles.userText : styles.botText,
        ]}
      >
        {item.text}
      </Text>
    </View>
  );

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <FlatList
        ref={flatListRef}
        data={messages}
        renderItem={renderMessage}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={styles.messageList}
        inverted={false}
      />
      <View style={styles.inputContainer}>
        <TextInput
          style={[styles.input, colorScheme === "dark" && styles.inputDark]}
          value={inputText}
          onChangeText={setInputText}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
          placeholderTextColor={colorScheme === "dark" ? "#888" : "#666"}
        />
        <TouchableOpacity onPress={handleSend} style={styles.sendButton}>
          <Feather name="send" size={24} color="#007AFF" />
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F0F0F0",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  messageList: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  messageBubble: {
    maxWidth: "80%",
    padding: 12,
    borderRadius: 16,
    marginBottom: 8,
  },
  userBubble: {
    alignSelf: "flex-end",
    backgroundColor: "#007AFF",
  },
  botBubble: {
    alignSelf: "flex-start",
    backgroundColor: "#E5E5EA",
  },
  messageText: {
    fontSize: 16,
  },
  userText: {
    color: "#FFFFFF",
  },
  botText: {
    color: "#000000",
  },
  inputContainer: {
    flexDirection: "row",
    padding: 16,
    backgroundColor: "#FFFFFF",
  },
  input: {
    flex: 1,
    height: 40,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    borderRadius: 20,
    paddingHorizontal: 16,
    marginRight: 8,
  },
  inputDark: {
    backgroundColor: "#333333",
    color: "#FFFFFF",
    borderColor: "#666666",
  },
  sendButton: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Chatbot;
