import React, { useEffect, useRef } from "react";
import { Animated, Easing, View } from "react-native";
import Svg, { Rect, Text as SvgText } from "react-native-svg";

const lightBackgroundColor = "#e6f0e8";
const darkBackgroundColor = "#1a2e22";

const CustomLoading = ({ width = 200, height = 50, isDarkMode = false }) => {
  const animatedValues = [
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
  ];

  useEffect(() => {
    const animations = animatedValues.map((value, index) =>
      Animated.loop(
        Animated.sequence([
          Animated.delay(index * 100),
          Animated.timing(value, {
            toValue: 1,
            duration: 500,
            easing: Easing.ease,
            useNativeDriver: false,
          }),
          Animated.timing(value, {
            toValue: 0,
            duration: 500,
            easing: Easing.ease,
            useNativeDriver: false,
          }),
        ])
      )
    );

    Animated.parallel(animations).start();

    return () => {
      animations.forEach((anim) => anim.stop());
    };
  }, []);

  const getColor = (index) => {
    const colors = isDarkMode
      ? ["#99d1a7", "#66b37d", "#338b4d", "#1e6738", "#004d25"]
      : ["#004d25", "#1e6738", "#338b4d", "#66b37d", "#99d1a7"];
    return animatedValues[index].interpolate({
      inputRange: [0, 1],
      outputRange: [colors[index], isDarkMode ? "#1a2e22" : "#e6f0e8"],
    });
  };

  return (
    <View>
      <Svg width={width} height={height} viewBox="0 0 800 200">
        <Rect
          width="800"
          height="200"
          fill={isDarkMode ? darkBackgroundColor : lightBackgroundColor}
        />
        <SvgText
          x="20"
          y="100"
          fontFamily="Times, serif"
          fontWeight="bold"
          fontSize="90"
          fill={isDarkMode ? "#99d1a7" : "#004d25"}
        >
          SBG
        </SvgText>
        <SvgText
          x="20"
          y="150"
          fontFamily="Arial, sans-serif"
          fontSize="24"
          fill={isDarkMode ? "#99d1a7" : "#004d25"}
        >
          Scott Business Group
        </SvgText>
        {animatedValues.map((_, index) => (
          <AnimatedRect
            key={index}
            x={230 + index * 60}
            y="40"
            width="50"
            height="50"
            fill={getColor(index)}
          />
        ))}
      </Svg>
    </View>
  );
};

const AnimatedRect = Animated.createAnimatedComponent(Rect);

export default CustomLoading;
