import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import axios from "axios";
import moment from "moment-timezone";
import CustomAlert from "./CustomAlert";

const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const PersonalLeave = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const [employeeName, setEmployeeName] = useState(
    route.params?.employeeName || ""
  );
  const [employeeId, setEmployeeId] = useState(route.params?.employeeId || "");
  const [clientName, setClientName] = useState(route.params?.department || "");
  const [leaveType, setLeaveType] = useState("");
  const [hoursClaimed, setHoursClaimed] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [totalDaysOff, setTotalDaysOff] = useState("");
  const [reasonForAbsence, setReasonForAbsence] = useState("");
  const [existingRequests, setExistingRequests] = useState([]);
  const [isSignatureChecked, setIsSignatureChecked] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [showCancel, setShowCancel] = useState(true);

  const showAlert = (message, title, onConfirm = null, showCancel = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertConfirm(() => onConfirm);
    setShowCancel(showCancel);
    setAlertVisible(true);
  };

  useEffect(() => {
    if (route.params?.employeeName) {
      setEmployeeName(route.params.employeeName);
    }
    if (route.params?.employeeId) {
      setEmployeeId(route.params.employeeId);
      fetchExistingRequests(route.params.employeeId);
    }
    if (route.params?.department) {
      setClientName(route.params.department);
    }

    if (route.params?.department) {
      setClientName(route.params.department);
    }
  }, [
    route.params?.employeeName,
    route.params?.employeeId,
    route.params?.department,
  ]);

  const fetchExistingRequests = async (empId) => {
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/personal-leave/${empId}`
      );
      setExistingRequests(response.data);
    } catch (error) {
      console.error("Error fetching existing requests:", error);
      showAlert(
        "Failed to fetch existing leave requests. Please try again later.",
        "Error",
        null,
        false
      );
    }
  };

  const handleLeaveTypeChange = (selectedType) => {
    setLeaveType(selectedType);
  };

  const validateForm = () => {
    if (!clientName.trim()) {
      showAlert("Client Name is not available", "Error", null, false);
      return false;
    }
    if (!leaveType) {
      showAlert("Please select a leave type", "Error", null, false);
      return false;
    }
    if (!hoursClaimed.trim()) {
      showAlert("Please enter the hours claimed", "Error", null, false);
      return false;
    }
    if (!dateFrom.trim() || !dateTo.trim()) {
      showAlert("Please enter both start and end dates", "Error", null, false);
      return false;
    }
    if (!totalDaysOff.trim()) {
      showAlert("Please enter the total days off", "Error", null, false);
      return false;
    }
    if (!reasonForAbsence.trim()) {
      showAlert("Please enter the reason for absence", "Error", null, false);
      return false;
    }
    if (!isSignatureChecked) {
      showAlert("Please sign the leave request", "Error", null, false);
      return false;
    }
    return true;
  };

  const validateDateFormat = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  const formatDateToUTC = (date) => {
    return moment.tz(date, "America/Phoenix").toISOString();
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    if (!validateDateFormat(dateFrom) || !validateDateFormat(dateTo)) {
      showAlert(
        "Please enter dates in the format YYYY-MM-DD",
        "Error",
        null,
        false
      );
      return;
    }

    try {
      const response = await axios.post(
        "https://sbg-backend.onrender.com/personal-leave",
        {
          employeeId,
          employeeName,
          clientName,
          leaveType,
          hoursClaimed: parseFloat(hoursClaimed),
          dateFrom: formatDateToUTC(dateFrom),
          dateTo: formatDateToUTC(dateTo),
          totalDaysOff: parseFloat(totalDaysOff),
          reasonForAbsence,
          isSigned: isSignatureChecked,
        }
      );

      showAlert(
        "Personal leave request submitted successfully",
        "Success",
        () => {
          fetchExistingRequests(employeeId);
          setLeaveType("");
          setHoursClaimed("");
          setDateFrom("");
          setDateTo("");
          setTotalDaysOff("");
          setReasonForAbsence("");
          setIsSignatureChecked(false);
        },
        false
      );
    } catch (error) {
      console.error("Error submitting leave request:", error);
      showAlert(
        "Failed to submit leave request. Please try again later.",
        "Error",
        null,
        false
      );
    }
  };

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  return (
    <SafeAreaView
      style={[styles.safeArea, colorScheme === "dark" && styles.safeAreaDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text
          style={[
            styles.headerText,
            colorScheme === "dark" && styles.headerTextDark,
          ]}
        >
          PERSONAL LEAVE REQUEST
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.scrollView}>
        <View style={[styles.container, isDesktop && styles.desktopContainer]}>
          <View
            style={[styles.formSection, isDesktop && styles.desktopFormSection]}
          >
            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Employee Name:
            </Text>
            <TextInput
              style={[styles.input, colorScheme === "dark" && styles.inputDark]}
              value={employeeName}
              editable={false}
            />

            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Client Name:
            </Text>
            <TextInput
              style={[styles.input, colorScheme === "dark" && styles.inputDark]}
              value={clientName}
              editable={false}
            />

            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Type of Leave:
            </Text>

            {["Earned Paid Sick Time", "Vacation", "Time off without Pay"].map(
              (type) => (
                <TouchableOpacity
                  key={type}
                  style={styles.checkboxContainer}
                  onPress={() => handleLeaveTypeChange(type)}
                >
                  <Feather
                    name={leaveType === type ? "check-square" : "square"}
                    size={24}
                    color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
                  />
                  <Text
                    style={[
                      styles.checkboxText,
                      colorScheme === "dark" && styles.checkboxTextDark,
                    ]}
                  >
                    {type}
                  </Text>
                </TouchableOpacity>
              )
            )}

            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Hours Claimed:
            </Text>
            <TextInput
              style={[styles.input, colorScheme === "dark" && styles.inputDark]}
              value={hoursClaimed}
              onChangeText={setHoursClaimed}
              keyboardType="numeric"
            />

            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Dates:
            </Text>
            <View style={styles.dateContainer}>
              <View style={styles.dateInput}>
                <Text
                  style={[
                    styles.dateLabel,
                    colorScheme === "dark" && styles.dateLabelDark,
                  ]}
                >
                  From:
                </Text>
                <TextInput
                  style={[
                    styles.input,
                    styles.dateTextInput,
                    colorScheme === "dark" && styles.inputDark,
                  ]}
                  value={dateFrom}
                  onChangeText={setDateFrom}
                  placeholder="YYYY-MM-DD"
                  placeholderTextColor={
                    colorScheme === "dark" ? "#666" : "#AAA"
                  }
                />
              </View>
              <View style={styles.dateInput}>
                <Text
                  style={[
                    styles.dateLabel,
                    colorScheme === "dark" && styles.dateLabelDark,
                  ]}
                >
                  To:
                </Text>
                <TextInput
                  style={[
                    styles.input,
                    styles.dateTextInput,
                    colorScheme === "dark" && styles.inputDark,
                  ]}
                  value={dateTo}
                  onChangeText={setDateTo}
                  placeholder="YYYY-MM-DD"
                  placeholderTextColor={
                    colorScheme === "dark" ? "#666" : "#AAA"
                  }
                />
              </View>
            </View>

            <Text
              style={[
                styles.infoText,
                colorScheme === "dark" && styles.infoTextDark,
              ]}
            >
              Earned Paid Sick Time
            </Text>
            <Text
              style={[
                styles.infoText,
                colorScheme === "dark" && styles.infoTextDark,
              ]}
            >
              Under the ARIZONA FAIR WAGES AND HEALTHY FAMILIES ACT, Earned Paid
              Sick Time may be used for the following purposes: (1) medical care
              for mental or physical illness, injury, or health conditions; or
              (2) a public health emergency declared by the state; and (3)
              absence due to domestic violence, sexual violence, abuse, or
              stalking. Employees may use earned paid sick time for themselves
              or the care of family members. Documentation is required for three
              or more consecutive days of absence. You can visit www.azica.gov
              for more information on this law.
            </Text>
            <Text
              style={[
                styles.infoText,
                colorScheme === "dark" && styles.infoTextDark,
              ]}
            >
              Check your latest paystub for the time accrued and hours you have
              used year-to-date. Time is shown in hours: minutes. Scott Business
              Group employees may accrue and use up to 40 hours in the calendar
              year. Paid Sick Time is accrued at the rate of one (1) hour for
              every 30 hours worked. It is the responsibility of the employee to
              immediately notify the Department Supervisor and Scott Business
              Group of your absence due to one of the above reasons.
            </Text>
            <Text
              style={[
                styles.infoText,
                colorScheme === "dark" && styles.infoTextDark,
              ]}
            >
              Vacation Employees who work full-time hours (40/hours/week) per
              week) of Scott Business Group may receive five days (40/hours)
              paid vacation after twelve months of continuous employment. The
              timing of vacation must have prior approval of the Department
              Supervisor and Scott Business Group.
            </Text>
            <Text
              style={[
                styles.infoText,
                colorScheme === "dark" && styles.infoTextDark,
              ]}
            >
              Time off without Pay (For example, jury duty, bereavement leave,
              maternity/paternity leave, military obligations or other leave
              without pay approved by Supervisor/Department).
            </Text>

            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Total days off from work:
            </Text>
            <TextInput
              style={[styles.input, colorScheme === "dark" && styles.inputDark]}
              value={totalDaysOff}
              onChangeText={setTotalDaysOff}
              keyboardType="numeric"
            />

            <Text
              style={[styles.label, colorScheme === "dark" && styles.labelDark]}
            >
              Reason for Absence:
            </Text>
            <TextInput
              style={[
                styles.input,
                styles.textArea,
                colorScheme === "dark" && styles.inputDark,
              ]}
              value={reasonForAbsence}
              onChangeText={setReasonForAbsence}
              multiline
            />

            <Text
              style={[
                styles.infoText,
                colorScheme === "dark" && styles.infoTextDark,
              ]}
            >
              You must submit requests for foreseen days off, other than sick
              leave five-ten days prior to the first day you will be absent.
            </Text>

            <TouchableOpacity
              style={styles.checkboxContainer}
              onPress={() => setIsSignatureChecked(!isSignatureChecked)}
            >
              <Feather
                name={isSignatureChecked ? "check-square" : "square"}
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
              />
              <Text
                style={[
                  styles.signatureCheckboxText,
                  colorScheme === "dark" && styles.signatureCheckboxTextDark,
                ]}
              >
                I hereby certify that the information given above is true and
                correct.
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.submitButton,
                colorScheme === "dark" && styles.submitButtonDark,
              ]}
              onPress={handleSubmit}
            >
              <Text style={styles.submitButtonText}>Submit Request</Text>
            </TouchableOpacity>
          </View>

          <View
            style={[
              styles.existingRequestsSection,
              isDesktop && styles.desktopExistingRequestsSection,
            ]}
          >
            <Text
              style={[
                styles.existingRequestsHeader,
                colorScheme === "dark" && styles.existingRequestsHeaderDark,
              ]}
            >
              Existing Leave Requests:
            </Text>
            {existingRequests.map((request, index) => (
              <View
                key={index}
                style={[
                  styles.requestItem,
                  colorScheme === "dark" && styles.requestItemDark,
                ]}
              >
                <Text
                  style={[
                    styles.requestItemText,
                    colorScheme === "dark" && styles.requestItemTextDark,
                  ]}
                >
                  Type: {request.leaveType}
                </Text>
                <Text
                  style={[
                    styles.requestItemText,
                    colorScheme === "dark" && styles.requestItemTextDark,
                  ]}
                >
                  From:{" "}
                  {moment
                    .tz(request.dateFrom, "America/Phoenix")
                    .format("YYYY-MM-DD")}
                </Text>
                <Text
                  style={[
                    styles.requestItemText,
                    colorScheme === "dark" && styles.requestItemTextDark,
                  ]}
                >
                  To:{" "}
                  {moment
                    .tz(request.dateTo, "America/Phoenix")
                    .format("YYYY-MM-DD")}
                </Text>
                <Text
                  style={[
                    styles.requestItemText,
                    colorScheme === "dark" && styles.requestItemTextDark,
                    request.status.toLowerCase() === "approved" &&
                      styles.statusApproved,
                    request.status.toLowerCase() === "denied" &&
                      styles.statusDenied,
                  ]}
                >
                  Status: {request.status}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
      <CustomAlert
        visible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
        onConfirm={alertConfirm}
        showCancel={showCancel}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  safeAreaDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  scrollView: {
    flex: 1,
  },
  container: {
    padding: 20,
  },
  desktopContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  formSection: {
    flex: 1,
  },
  desktopFormSection: {
    width: "60%",
    marginRight: 20,
  },
  label: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
    marginBottom: 8,
  },
  labelDark: {
    color: "#FFFFFF",
  },
  input: {
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 8,
    padding: 12,
    marginBottom: 16,
    fontSize: 16,
    backgroundColor: "white",
  },
  inputDark: {
    backgroundColor: "#333",
    color: "#FFFFFF",
    borderColor: "#555",
  },
  dateContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  dateInput: {
    flex: 1,
    marginRight: 8,
  },
  dateLabel: {
    fontSize: 14,
    color: "#333",
    marginBottom: 4,
  },
  dateLabelDark: {
    color: "#FFFFFF",
  },
  dateTextInput: {
    marginBottom: 0,
  },
  textArea: {
    height: 100,
    textAlignVertical: "top",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  checkboxText: {
    fontSize: 16,
    marginLeft: 8,
  },
  checkboxTextDark: {
    color: "#FFFFFF",
  },
  infoText: {
    fontSize: 14,
    color: "#333",
    marginBottom: 16,
    lineHeight: 20,
  },
  infoTextDark: {
    color: "#AAAAAA",
  },
  signatureCheckboxText: {
    fontSize: 14,
    marginLeft: 8,
  },
  signatureCheckboxTextDark: {
    color: "#FFFFFF",
  },
  submitButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 8,
    alignItems: "center",
    marginBottom: 20,
  },
  submitButtonDark: {
    backgroundColor: "#0A84FF",
  },
  submitButtonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "600",
  },
  existingRequestsSection: {
    marginTop: 20,
  },
  desktopExistingRequestsSection: {
    width: "35%",
    marginTop: 0,
  },
  existingRequestsHeader: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
    color: "#333",
  },
  existingRequestsHeaderDark: {
    color: "#FFFFFF",
  },
  requestItem: {
    backgroundColor: "white",
    padding: 16,
    marginBottom: 12,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 3,
  },
  requestItemDark: {
    backgroundColor: "#333",
    shadowColor: "#FFF",
  },
  requestItemText: {
    fontSize: 16,
    marginBottom: 4,
    color: "#333",
  },
  requestItemTextDark: {
    color: "#FFFFFF",
  },
  statusApproved: {
    color: "#4CAF50",
  },
  statusDenied: {
    color: "#F44336",
  },
});

export default PersonalLeave;
