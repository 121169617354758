import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import {
  View,
  TextInput,
  FlatList,
  Text,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";

const BackButton = ({ color }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const Employees = () => {
  const [input, setInput] = useState("");
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(
        "https://sbg-backend.onrender.com/employees"
      );
      const sortedEmployees = response.data.sort((a, b) =>
        a.employeeName.localeCompare(b.employeeName)
      );
      setEmployees(sortedEmployees);
      setFilteredEmployees(sortedEmployees);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    const filtered = employees.filter((employee) =>
      employee.employeeName
        ? employee.employeeName.toLowerCase().includes(input.toLowerCase()) ||
          (employee.supervisorId &&
            employee.supervisorId.toString().includes(input))
        : false
    );
    setFilteredEmployees(filtered);
  }, [input, employees]);

  useFocusEffect(
    React.useCallback(() => {
      fetchEmployeeData();
    }, [])
  );

  const renderEmployeeItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => navigation.navigate("Edit Employee", { employee: item })}
      style={[
        styles.employeeItem,
        colorScheme === "dark" && styles.employeeItemDark,
        isDesktop && styles.desktopEmployeeItem,
      ]}
    >
      <View
        style={[
          styles.employeeAvatar,
          colorScheme === "dark" && styles.employeeAvatarDark,
        ]}
      >
        <Text style={styles.avatarText}>{item.employeeName.charAt(0)}</Text>
      </View>
      <View style={styles.employeeInfo}>
        <Text
          style={[
            styles.employeeName,
            colorScheme === "dark" && styles.employeeNameDark,
          ]}
        >
          {item.employeeName}
        </Text>
        <Text
          style={[
            styles.employeeDetails,
            colorScheme === "dark" && styles.employeeDetailsDark,
          ]}
        >
          {item.position} - {item.department}
        </Text>
        <Text
          style={[
            styles.employeeId,
            colorScheme === "dark" && styles.employeeIdDark,
          ]}
        >
          ID: {item.employeeId}
        </Text>
      </View>
      <Feather
        name="chevron-right"
        size={24}
        color={colorScheme === "dark" ? "#666" : "#C7C7CC"}
      />
    </TouchableOpacity>
  );

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text
          style={[styles.title, colorScheme === "dark" && styles.titleDark]}
        >
          Employee Database
        </Text>
        <View style={styles.headerRightContainer}>
          <TouchableOpacity
            onPress={toggleColorScheme}
            style={styles.themeToggle}
          >
            <Feather
              name={colorScheme === "dark" ? "sun" : "moon"}
              size={24}
              color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.addButton}
            onPress={() => navigation.navigate("Add New Employee")}
          >
            <Feather
              name="plus"
              size={24}
              color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        <View
          style={[
            styles.searchContainer,
            colorScheme === "dark" && styles.searchContainerDark,
          ]}
        >
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#8E8E93" : "#8E8E93"}
            style={styles.searchIcon}
          />
          <TextInput
            value={input}
            onChangeText={(text) => setInput(text)}
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search employees"
            placeholderTextColor={
              colorScheme === "dark" ? "#8E8E93" : "#8E8E93"
            }
          />
        </View>
        <Text
          style={[
            styles.employeeCount,
            colorScheme === "dark" && styles.employeeCountDark,
          ]}
        >
          Employees: {filteredEmployees.length}
        </Text>
        {filteredEmployees.length > 0 ? (
          <FlatList
            data={filteredEmployees}
            renderItem={renderEmployeeItem}
            keyExtractor={(item) => item.employeeId}
            contentContainerStyle={[
              styles.listContent,
              isDesktop && styles.desktopListContent,
            ]}
            numColumns={isDesktop ? 2 : 1}
            key={isDesktop ? "desktop" : "mobile"}
          />
        ) : (
          <View style={styles.noDataContainer}>
            <Text
              style={[
                styles.noDataText,
                colorScheme === "dark" && styles.noDataTextDark,
              ]}
            >
              No employees found
            </Text>
            <Text
              style={[
                styles.noDataSubtext,
                colorScheme === "dark" && styles.noDataSubtextDark,
              ]}
            >
              Press the plus button to add an employee
            </Text>
            <TouchableOpacity
              style={[
                styles.addEmployeeButton,
                colorScheme === "dark" && styles.addEmployeeButtonDark,
              ]}
              onPress={() => navigation.navigate("Add New Employee")}
            >
              <Feather name="plus" size={24} color="white" />
              <Text style={styles.addEmployeeButtonText}>Add Employee</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  themeToggle: {
    marginRight: 16,
  },
  backButton: {
    padding: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  titleDark: {
    color: "#FFFFFF",
  },
  addButton: {
    padding: 8,
  },
  content: {
    flex: 1,
  },
  desktopContent: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    marginHorizontal: 16,
    marginVertical: 12,
    paddingHorizontal: 12,
    height: 44,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  searchContainerDark: {
    backgroundColor: "#333",
    shadowColor: "#FFF",
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#000",
  },
  searchInputDark: {
    color: "#FFFFFF",
  },
  employeeCount: {
    fontSize: 14,
    fontWeight: "600",
    color: "#8E8E93",
    marginHorizontal: 16,
    marginBottom: 8,
  },
  employeeCountDark: {
    color: "#CCCCCC",
  },
  listContent: {
    paddingHorizontal: 16,
  },
  desktopListContent: {
    paddingHorizontal: 0,
  },
  employeeItem: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 12,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  employeeItemDark: {
    backgroundColor: "#1E1E1E",
    shadowColor: "#FFF",
  },
  desktopEmployeeItem: {
    flex: 1,
    marginHorizontal: 8,
  },
  employeeAvatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#007AFF",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  employeeAvatarDark: {
    backgroundColor: "#0A84FF",
  },
  avatarText: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  employeeInfo: {
    flex: 1,
  },
  employeeName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#000",
  },
  employeeNameDark: {
    color: "#FFFFFF",
  },
  employeeDetails: {
    fontSize: 14,
    color: "#8E8E93",
    marginTop: 2,
  },
  employeeDetailsDark: {
    color: "#CCCCCC",
  },
  employeeId: {
    fontSize: 12,
    color: "#8E8E93",
    marginTop: 2,
  },
  employeeIdDark: {
    color: "#CCCCCC",
  },
  noDataContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 16,
  },
  noDataText: {
    fontSize: 18,
    fontWeight: "600",
    color: "#000",
    marginBottom: 8,
  },
  noDataTextDark: {
    color: "#FFFFFF",
  },
  noDataSubtext: {
    fontSize: 14,
    color: "#8E8E93",
    marginBottom: 24,
    textAlign: "center",
  },
  noDataSubtextDark: {
    color: "#CCCCCC",
  },
  addEmployeeButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#007AFF",
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
  },
  addEmployeeButtonDark: {
    backgroundColor: "#0A84FF",
  },
  addEmployeeButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 8,
  },
});

export default Employees;
