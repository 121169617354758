import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import {
  Feather,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { FIREBASE_AUTH, FIREBASE_DB } from "../../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import Svg, { Rect, Text as SvgText } from "react-native-svg";
import moment from "moment";

const lightBackgroundColor = "#e6f0e8";
const darkBackgroundColor = "#1a2e22";

const Logo = ({ width = 200, height = 50, isDarkMode = false }) => (
  <Svg width={width} height={height} viewBox="0 0 800 200">
    <Rect
      width="800"
      height="200"
      fill={isDarkMode ? darkBackgroundColor : lightBackgroundColor}
    />
    <SvgText
      x="20"
      y="100"
      fontFamily="Times, serif"
      fontWeight="bold"
      fontSize="90"
      fill={isDarkMode ? "#99d1a7" : "#004d25"}
    >
      SBG
    </SvgText>
    <SvgText
      x="20"
      y="150"
      fontFamily="Arial, sans-serif"
      fontSize="24"
      fill={isDarkMode ? "#99d1a7" : "#004d25"}
    >
      Scott Business Group
    </SvgText>
    <Rect
      x="230"
      y="40"
      width="50"
      height="50"
      fill={isDarkMode ? "#99d1a7" : "#004d25"}
    />
    <Rect
      x="290"
      y="40"
      width="50"
      height="50"
      fill={isDarkMode ? "#66b37d" : "#1e6738"}
    />
    <Rect
      x="350"
      y="40"
      width="50"
      height="50"
      fill={isDarkMode ? "#338b4d" : "#338b4d"}
    />
    <Rect
      x="410"
      y="40"
      width="50"
      height="50"
      fill={isDarkMode ? "#1e6738" : "#66b37d"}
    />
    <Rect
      x="470"
      y="40"
      width="50"
      height="50"
      fill={isDarkMode ? "#004d25" : "#99d1a7"}
    />
  </Svg>
);

const getGreeting = () => {
  const hour = moment().hour();
  if (hour < 12) return "Good morning";
  if (hour < 18) return "Good afternoon";
  return "Good evening";
};

const Greeting = ({ userName, colorScheme }) => (
  <View style={styles.greetingContainer}>
    <Text
      style={[
        styles.greetingText,
        colorScheme === "dark" && styles.greetingTextDark,
      ]}
    >
      {getGreeting()}, {userName}!
    </Text>
  </View>
);

const SupervisorDashboard = () => {
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserName = async () => {
      const user = FIREBASE_AUTH.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(FIREBASE_DB, "users", user.uid));
        if (userDoc.exists()) {
          setUserName(userDoc.data().name);
        }
      }
    };

    fetchUserName();
  }, []);

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const MenuItem = ({ icon, title, onPress }) => (
    <TouchableOpacity
      style={[
        styles.menuItem,
        colorScheme === "dark" && styles.menuItemDark,
        isDesktop && styles.desktopMenuItem,
      ]}
      onPress={onPress}
    >
      {icon}
      <Text
        style={[
          styles.menuItemText,
          colorScheme === "dark" && styles.menuItemTextDark,
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );

  return (
    <SafeAreaView
      style={[
        styles.safeArea,
        {
          backgroundColor:
            colorScheme === "dark" ? darkBackgroundColor : lightBackgroundColor,
        },
      ]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={
          colorScheme === "dark" ? darkBackgroundColor : lightBackgroundColor
        }
      />
      <View
        style={[
          styles.container,
          {
            backgroundColor:
              colorScheme === "dark"
                ? darkBackgroundColor
                : lightBackgroundColor,
          },
        ]}
      >
        <View
          style={[styles.header, colorScheme === "dark" && styles.headerDark]}
        >
          <Logo width={300} height={75} isDarkMode={colorScheme === "dark"} />
          <View style={styles.headerRightContainer}>
            <TouchableOpacity
              onPress={toggleColorScheme}
              style={styles.themeToggle}
            >
              <Feather
                name={colorScheme === "dark" ? "sun" : "moon"}
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#4A4A4A"}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.logoutButton}
              onPress={() => {
                FIREBASE_AUTH.signOut()
                  .then(() => console.log("User signed out!"))
                  .catch((error) => console.error("Sign Out Error", error));
              }}
            >
              <Feather
                name="log-out"
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#4A4A4A"}
              />
            </TouchableOpacity>
          </View>
        </View>

        <Greeting userName={userName} colorScheme={colorScheme} />

        <ScrollView
          contentContainerStyle={[
            styles.scrollView,
            isDesktop && styles.desktopScrollView,
          ]}
        >
          <View style={[styles.menuGrid, isDesktop && styles.desktopMenuGrid]}>
            <MenuItem
              icon={
                <MaterialCommunityIcons
                  name="account-check"
                  size={24}
                  color="#FF2D55"
                />
              }
              title="Approve Timesheets"
              onPress={() => navigation.navigate("Supervisor Approval")}
            />
            <MenuItem
              icon={
                <MaterialIcons
                  name="assignment-turned-in"
                  size={24}
                  color="#34C759"
                />
              }
              title="Employee Attendance"
              onPress={() => navigation.navigate("Attendance")}
            />
            <MenuItem
              icon={
                <MaterialCommunityIcons
                  name="file-document"
                  size={24}
                  color="#007AFF"
                />
              }
              title="Leave Report"
              onPress={() => navigation.navigate("Leave Report")}
            />
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#E1E1E1",
  },
  headerDark: {
    borderBottomColor: "#333",
  },
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  themeToggle: {
    marginRight: 16,
  },
  scrollView: {
    flexGrow: 1,
  },
  desktopScrollView: {
    alignItems: "center",
  },
  menuGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  desktopMenuGrid: {
    maxWidth: 1200,
    width: "100%",
  },
  menuItem: {
    width: "48%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 20,
    marginBottom: 15,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  menuItemDark: {
    backgroundColor: "#333",
    shadowColor: "#FFF",
  },
  desktopMenuItem: {
    width: "30%",
    marginHorizontal: "1.5%",
  },
  menuItemText: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "500",
    color: "#4A4A4A",
    textAlign: "center",
  },
  menuItemTextDark: {
    color: "#FFFFFF",
  },
  logoutButton: {
    padding: 10,
  },
  greetingContainer: {
    marginBottom: 20,
    alignItems: "center",
  },
  greetingText: {
    fontSize: 24,
    fontWeight: "600",
    color: "#4A4A4A",
  },
  greetingTextDark: {
    color: "#FFFFFF",
  },
});

export default SupervisorDashboard;
