// Import statements
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  TextInput,
  Pressable,
  FlatList,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import axios from "axios";
import moment from "moment-timezone";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import CustomLoading from "./CustomLoading";

const BackButton = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color="#007AFF" />
    </TouchableOpacity>
  );
};

const LeaveReport = () => {
  // State variables
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [userData, setUserData] = useState(null);

  // Functions
  const fetchUserData = useCallback(async () => {
    const user = FIREBASE_AUTH.currentUser;
    if (user) {
      try {
        const db = getFirestore();
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData({ ...docSnap.data(), uid: user.uid });
        } else {
          console.log("No user data found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
      }
    }
  }, []);

  const fetchLeaveRequests = useCallback(async () => {
    if (!userData) return;

    setLoading(true);
    setError(null);
    try {
      const startDate = currentMonth
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = currentMonth.clone().endOf("month").format("YYYY-MM-DD");

      let allEmployeesResponse;
      if (userData.role === "admin") {
        allEmployeesResponse = await axios.get(
          "https://sbg-backend.onrender.com/employees"
        );
      } else if (userData.role === "supervisor") {
        allEmployeesResponse = await axios.get(
          "https://sbg-backend.onrender.com/employees",
          { params: { supervisorName: userData.name } }
        );
      } else {
        allEmployeesResponse = {
          data: [
            {
              employeeId: userData.employeeId,
              employeeName: userData.name,
            },
          ],
        };
      }

      const leaveRequests = await Promise.all(
        allEmployeesResponse.data.map(async (employee) => {
          const leaveResponse = await axios.get(
            `https://sbg-backend.onrender.com/personal-leave/${employee.employeeId}`,
            { params: { startDate, endDate } }
          );
          return leaveResponse.data.map((request) => ({
            ...request,
            employeeName: employee.employeeName,
            employeeId: employee.employeeId,
            dateFrom: new Date(request.dateFrom),
            dateTo: new Date(request.dateTo),
            approvedDate: request.approvedDate
              ? new Date(request.approvedDate)
              : null,
          }));
        })
      );

      const flattenedLeaveRequests = leaveRequests.flat();
      setLeaveRequests(flattenedLeaveRequests);
      setFilteredLeaveRequests(flattenedLeaveRequests);
    } catch (err) {
      console.error("Error fetching leave requests:", err);
      setError(
        err.message || "Failed to fetch leave requests. Please try again."
      );
    } finally {
      setLoading(false);
    }
  }, [userData, currentMonth]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (userData) {
      fetchLeaveRequests();
    }
  }, [fetchLeaveRequests, userData, currentMonth]);

  useEffect(() => {
    const filtered = leaveRequests.filter((request) =>
      request.employeeName.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredLeaveRequests(filtered);
  }, [searchInput, leaveRequests]);

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  const renderLeaveRequest = ({ item: request }) => (
    <Pressable
      onPress={() =>
        navigation.navigate("Leave Request", {
          employeeId: request.employeeId,
          requestId: request._id,
        })
      }
      style={({ pressed }) => [
        styles.requestItem,
        colorScheme === "dark" && styles.requestItemDark,
        {
          backgroundColor: pressed
            ? colorScheme === "dark"
              ? "#333"
              : "#F0F0F0"
            : colorScheme === "dark"
            ? "#1E1E1E"
            : "white",
        },
      ]}
    >
      <View style={styles.requestInfo}>
        {/* Employee Name */}
        <Text
          style={[
            styles.employeeName,
            colorScheme === "dark" && styles.employeeNameDark,
          ]}
        >
          {request.employeeName}
        </Text>
        {/* Date Range */}
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          {moment(request.dateFrom).format("MMM D")} -{" "}
          {moment(request.dateTo).format("MMM D, YYYY")}
        </Text>
        {/* Additional Fields */}
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Client: {request.clientName}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Leave Type: {request.leaveType}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Total Days Off: {request.totalDaysOff}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Reason: {request.reasonForAbsence}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Status: {request.status}
        </Text>
        {request.approvedBy && (
          <Text
            style={[
              styles.requestDetail,
              colorScheme === "dark" && styles.requestDetailDark,
            ]}
          >
            Approved By: {request.approvedBy}
          </Text>
        )}
        {request.approvedDate && (
          <Text
            style={[
              styles.requestDetail,
              colorScheme === "dark" && styles.requestDetailDark,
            ]}
          >
            Approved Date: {moment(request.approvedDate).format("MMM D, YYYY")}
          </Text>
        )}
      </View>
      <Feather name="chevron-right" size={24} color="#C7C7CC" />
    </Pressable>
  );

  if (loading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={[styles.container, dynamicStyles.container]}>
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#000" : "#F0F0F0"}
      />
      <View style={styles.content}>
        {/* Header */}
        <View
          style={[styles.header, colorScheme === "dark" && styles.headerDark]}
        >
          <BackButton />
          <Text
            style={[styles.title, colorScheme === "dark" && styles.titleDark]}
          >
            Leave Report
          </Text>
          <TouchableOpacity
            onPress={toggleColorScheme}
            style={styles.themeToggle}
          >
            <Feather
              name={colorScheme === "dark" ? "sun" : "moon"}
              size={24}
              color="#007AFF"
            />
          </TouchableOpacity>
        </View>

        {/* Search Input */}
        <View
          style={[
            styles.searchContainer,
            colorScheme === "dark" && styles.searchContainerDark,
          ]}
        >
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#8E8E93" : "#8E8E93"}
            style={styles.searchIcon}
          />
          <TextInput
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search by name"
            placeholderTextColor={
              colorScheme === "dark" ? "#8E8E93" : "#8E8E93"
            }
            value={searchInput}
            onChangeText={setSearchInput}
          />
        </View>

        {/* Leave Requests */}
        <FlatList
          data={filteredLeaveRequests}
          renderItem={renderLeaveRequest}
          keyExtractor={(item) => item._id}
          ListEmptyComponent={
            <Text
              style={[
                styles.noRequestsText,
                colorScheme === "dark" && styles.noRequestsTextDark,
              ]}
            >
              No leave requests found for this period.
            </Text>
          }
          contentContainerStyle={styles.requestList}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  // Container styles
  container: {
    flex: 1,
  },
  // Header styles
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333333",
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000000",
  },
  titleDark: {
    color: "#FFFFFF",
  },
  // Search container styles
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginHorizontal: 16,
    marginVertical: 12,
    paddingHorizontal: 12,
    height: 44,
  },
  searchContainerDark: {
    backgroundColor: "#2C2C2E",
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#000000",
  },
  searchInputDark: {
    color: "#FFFFFF",
  },
  // Request item styles
  requestItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  requestItemDark: {
    borderBottomColor: "#333333",
  },
  requestInfo: {
    flex: 1,
  },
  employeeName: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#000000",
  },
  employeeNameDark: {
    color: "#FFFFFF",
  },
  requestDetail: {
    fontSize: 16,
    color: "#000000",
  },
  requestDetailDark: {
    color: "#D1D1D6",
  },
  // No requests text
  noRequestsText: {
    fontSize: 16,
    textAlign: "center",
    marginTop: 20,
    color: "#000000",
  },
  noRequestsTextDark: {
    color: "#FFFFFF",
  },
  content: {
    flex: 1,
  },
  requestList: {
    flexGrow: 1,
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
});

export default LeaveReport;
