import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  ScrollView,
  SafeAreaView,
  useColorScheme,
  Platform,
} from "react-native";
import { FIREBASE_AUTH, FIREBASE_DB } from "../../FirebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
const AdminRegistration = ({ route, navigation }) => {
  const {
    prefilledEmail,
    prefilledName,
    prefilledPassword,
    prefilledRole,
    onComplete,
  } = route.params || {};

  const [email, setEmail] = useState(prefilledEmail || "");
  const [password, setPassword] = useState(prefilledPassword || "");
  const [name, setName] = useState(prefilledName || "");
  const [role, setRole] = useState(prefilledRole || "user");
  const [loading, setLoading] = useState(false);

  const colorScheme = useColorScheme();
  const isDarkMode = colorScheme === "dark";

  const handleRegister = async () => {
    if (!name || !email || !password) {
      Alert.alert("Error", "Please fill in all fields");
      return;
    }

    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        FIREBASE_AUTH,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(FIREBASE_DB, "users", user.uid), {
        name: name.replace(/Supervisor \((.*?)\)/, "$1"),
        email,
        role,
      });

      Alert.alert("Success", "User registered successfully");
      navigation.goBack();

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.error("Error registering user:", error);
      Alert.alert("Error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const generateRandomPassword = () => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let newPassword = "";
    for (let i = 0; i < 12; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setPassword(newPassword);
  };

  const inputStyle = [
    styles.input,
    isDarkMode && styles.inputDark,
    Platform.OS === "web" && webStyles.input,
  ];

  return (
    <SafeAreaView
      style={[styles.container, isDarkMode && styles.containerDark]}
    >
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Text style={[styles.title, isDarkMode && styles.titleDark]}>
          Register New User
        </Text>
        <View style={styles.form}>
          <TextInput
            style={inputStyle}
            placeholder="Name"
            placeholderTextColor={isDarkMode ? "#888" : "#666"}
            value={name}
            onChangeText={setName}
          />
          <TextInput
            style={inputStyle}
            placeholder="Email"
            placeholderTextColor={isDarkMode ? "#888" : "#666"}
            value={email}
            onChangeText={setEmail}
            keyboardType="email-address"
            autoCapitalize="none"
          />
          <View style={styles.passwordContainer}>
            <TextInput
              style={[inputStyle, styles.passwordInput]}
              placeholder="Password"
              placeholderTextColor={isDarkMode ? "#888" : "#666"}
              value={password}
              onChangeText={setPassword}
              secureTextEntry
            />
            <TouchableOpacity
              style={styles.generateButton}
              onPress={generateRandomPassword}
            >
              <Text style={styles.generateButtonText}>Generate</Text>
            </TouchableOpacity>
          </View>
          {Platform.OS === "web" ? (
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={{
                ...styles.input,
                ...(isDarkMode ? styles.inputDark : {}),
                ...webStyles.select,
              }}
            >
              <option value="user">User</option>
              <option value="supervisor">Supervisor</option>
              <option value="admin">Admin</option>
            </select>
          ) : (
            <View
              style={[
                styles.pickerContainer,
                isDarkMode && styles.pickerContainerDark,
              ]}
            >
              {/* Add a native picker for mobile if needed */}
            </View>
          )}
          <TouchableOpacity
            style={[styles.button, loading && styles.buttonDisabled]}
            onPress={handleRegister}
            disabled={loading}
          >
            <Text style={styles.buttonText}>
              {loading ? "Registering..." : "Register User"}
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f5f5f5",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
    color: "#000",
  },
  titleDark: {
    color: "#fff",
  },
  form: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  input: {
    height: 50,
    borderColor: "#ddd",
    borderWidth: 1,
    marginBottom: 15,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: 16,
    backgroundColor: "#fff",
  },
  inputDark: {
    backgroundColor: "#333",
    borderColor: "#666",
    color: "#fff",
  },
  button: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  buttonDisabled: {
    opacity: 0.7,
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  passwordContainer: {
    flexDirection: "row",
    marginBottom: 15,
  },
  passwordInput: {
    flex: 1,
    marginBottom: 0,
  },
  generateButton: {
    backgroundColor: "#007AFF",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
    marginLeft: 10,
    borderRadius: 5,
  },
  generateButtonText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
  },
});

const webStyles = {
  input: {
    outlineStyle: "none",
  },
  select: {
    height: 50,
    borderColor: "#ddd",
    borderWidth: 1,
    marginBottom: 15,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: 16,
    backgroundColor: "#fff",
    appearance: "none",
    WebkitAppearance: "none",
    MozAppearance: "none",
  },
};

export default AdminRegistration;
