import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { StyleSheet, Platform, View, TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";
import { onAuthStateChanged } from "firebase/auth";
import { FIREBASE_AUTH } from "./FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";

// Import existing components
import Login from "./app/(home)/Login2";
import Index from "./app/(home)/Index";
import Userprofile from "./app/(home)/[Userprofile]";
import Employees from "./app/(home)/Employees";
import Adddetails from "./app/(home)/Adddetails";
import Markattendance from "./app/(home)/Markattendance";
import Supervisorapproval from "./app/(home)/Supervisorapproval";
import SupervisorDashboard from "./app/(home)/SupervisorDashboard";
import Allattendance from "./app/(home)/Allattendance";
import EmployeeProfile from "./app/(home)/EmployeeProfile";
import Employeesummary from "./app/(home)/Employeesummary";
import AttendanceHistory from "./app/(home)/AttendanceHistory";
import WeeklyAttendanceReport from "./app/(home)/WeeklyAttendanceReport";
import PersonalLeave from "./app/(home)/PersonalLeave";
import LeaveRequest from "./app/(home)/LeaveRequest";
import LeaveReport from "./app/(home)/LeaveReport";
import CustomTimesheet from "./app/(home)/CustomTimesheet";
import PendingLeaveRequests from "./app/(home)/PendingLeaveRequests";
import AuthDatabase from "./app/(home)/AuthDatabase";
import AdminRegistration from "./app/(home)/AdminRegistration";
import GlobalBackHandler from "./app/(home)/GlobalBackHandler";

// Import the new Chatbot component
import Chatbot from "./app/(home)/Chatbot";

const Stack = createNativeStackNavigator();
const InsideStack = createNativeStackNavigator();
const UserStack = createNativeStackNavigator();
const SupervisorStack = createNativeStackNavigator();

function InsideStackScreen() {
  return (
    <>
      {Platform.OS === "web" && <GlobalBackHandler />}
      <InsideStack.Navigator screenOptions={{ headerShown: false }}>
        <InsideStack.Screen
          name="Admin Dashboard"
          component={Index}
          options={{ headerShown: false }}
        />
        <InsideStack.Screen
          name="Employee Database"
          component={Employees}
          options={{ headerShadowVisible: false, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Add New Employee"
          component={Adddetails}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Timesheet"
          component={Userprofile}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Attendance Report"
          component={Allattendance}
          options={{ headerShown: false }}
        />
        <InsideStack.Screen
          name="Edit Employee"
          component={EmployeeProfile}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Attendance"
          component={Markattendance}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Supervisor Approval"
          component={Supervisorapproval}
          options={{ headerShadowVisible: false, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Timeesheets/Personal Leave"
          component={Employeesummary}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Attendance History"
          component={AttendanceHistory}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Weekly Attendance Report"
          component={WeeklyAttendanceReport}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Personal Leave"
          component={PersonalLeave}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Leave Request"
          component={LeaveRequest}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Leave Report"
          component={LeaveReport}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Custom Timesheet"
          component={CustomTimesheet}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Pending Leave Requests"
          component={PendingLeaveRequests}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="Authentication Database"
          component={AuthDatabase}
          options={{ headerShadowVisible: true, headerTitle: "" }}
        />
        <InsideStack.Screen
          name="AdminRegistration"
          component={AdminRegistration}
          options={{
            headerShadowVisible: true,
            headerTitle: "Register New User",
          }}
        />
      </InsideStack.Navigator>
    </>
  );
}

function UserStackScreen() {
  return (
    <>
      {Platform.OS === "web" && <GlobalBackHandler />}
      <UserStack.Navigator>
        <UserStack.Screen
          name="Attendance"
          component={Markattendance}
          options={{ headerShown: false }}
        />
        <UserStack.Screen
          name="Timesheet"
          component={Userprofile}
          options={{ headerShown: false }}
        />

        <UserStack.Screen
          name="Attendance History"
          component={AttendanceHistory}
          options={{ headerShown: false }}
        />
        <UserStack.Screen
          name="Personal Leave"
          component={PersonalLeave}
          options={{ headerShown: false }}
        />
        <UserStack.Screen
          name="Custom Timesheet"
          component={CustomTimesheet}
          options={{ headerShown: false }}
        />
      </UserStack.Navigator>
    </>
  );
}

function SupervisorStackScreen() {
  return (
    <>
      {Platform.OS === "web" && <GlobalBackHandler />}
      <SupervisorStack.Navigator>
        <SupervisorStack.Screen
          name="Supervisor Dashboard"
          component={SupervisorDashboard}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Supervisor Approval"
          component={Supervisorapproval}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Timeesheets/Personal Leave"
          component={Employeesummary}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Attendance"
          component={Markattendance}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Timesheet"
          component={Userprofile}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Attendance History"
          component={AttendanceHistory}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Personal Leave"
          component={PersonalLeave}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Leave Request"
          component={LeaveRequest}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Leave Report"
          component={LeaveReport}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Custom Timesheet"
          component={CustomTimesheet}
          options={{ headerShown: false }}
        />
        <SupervisorStack.Screen
          name="Pending Leave Requests"
          component={PendingLeaveRequests}
          options={{ headerShown: false }}
        />
      </SupervisorStack.Navigator>
    </>
  );
}

export default function App() {
  const [user, setUser] = useState(null);
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      FIREBASE_AUTH,
      async (currentUser) => {
        if (currentUser) {
          const db = getFirestore();
          const docRef = doc(db, "users", currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUser({ ...currentUser, role: userData.role });
          } else {
            console.log("No such document!");
          }
        } else {
          setUser(null);
        }
      }
    );

    return unsubscribe;
  }, []);

  return (
    <View style={styles.container}>
      <NavigationContainer>
        {Platform.OS === "web" && <GlobalBackHandler />}
        <Stack.Navigator>
          {user ? (
            user.role === "admin" ? (
              <Stack.Screen
                name="InsideStack"
                component={InsideStackScreen}
                options={{ headerShown: false }}
              />
            ) : user.role === "supervisor" ? (
              <Stack.Screen
                name="SupervisorStack"
                component={SupervisorStackScreen}
                options={{ headerShown: false }}
              />
            ) : (
              <Stack.Screen
                name="UserStack"
                component={UserStackScreen}
                options={{ headerShown: false }}
              />
            )
          ) : (
            <Stack.Screen
              name="Login"
              component={Login}
              options={{ headerShown: false }}
            />
          )}
        </Stack.Navigator>
      </NavigationContainer>

      {user && (
        <>
          <TouchableOpacity
            style={styles.chatbotButton}
            onPress={() => setIsChatbotVisible(!isChatbotVisible)}
          >
            <Feather name="message-circle" size={24} color="#007AFF" />
          </TouchableOpacity>

          {isChatbotVisible && (
            <View style={styles.chatbotContainer}>
              <Chatbot colorScheme="light" />
              <TouchableOpacity
                style={styles.closeChatbotButton}
                onPress={() => setIsChatbotVisible(false)}
              >
                <Feather name="x" size={24} color="#007AFF" />
              </TouchableOpacity>
            </View>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chatbotButton: {
    position: "absolute",
    bottom: 20,
    right: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 30,
    width: 60,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: 1000,
  },
  chatbotContainer: {
    position: "absolute",
    bottom: 90,
    right: 20,
    width: 300,
    height: 400,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: 1000,
  },
  closeChatbotButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1001,
  },
});
