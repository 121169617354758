import React, { useState, useEffect } from "react";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  ActivityIndicator,
  Pressable,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import axios from "axios";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Feather } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment-timezone";

const BackButton = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color="#007AFF" />
    </TouchableOpacity>
  );
};

const PendingLeaveRequests = () => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  useFocusEffect(
    React.useCallback(() => {
      const fetchPendingLeaveRequests = async () => {
        try {
          setLoading(true);

          const currentUser = FIREBASE_AUTH.currentUser;
          if (!currentUser) {
            console.log("No user logged in");
            return;
          }

          const db = getFirestore();
          const docRef = doc(db, "users", currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const isAdmin = userData.role === "admin";
            const isSupervisor = userData.role === "supervisor";
            const supervisorName = userData.name;

            let allEmployeesResponse;
            if (isAdmin) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees"
              );
            } else if (isSupervisor) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                { params: { supervisorName: supervisorName } }
              );
            } else {
              console.log("User is not authorized to view leave requests");
              return;
            }

            const pendingLeaveRequests = await Promise.all(
              allEmployeesResponse.data.map(async (employee) => {
                const leaveResponse = await axios.get(
                  `https://sbg-backend.onrender.com/personal-leave/${employee.employeeId}`
                );
                const pendingRequests = leaveResponse.data.filter(
                  (leave) => leave.status === "Pending"
                );
                return pendingRequests.map((request) => ({
                  ...request,
                  employeeName: employee.employeeName,
                  employeeId: employee.employeeId,
                }));
              })
            );

            const flattenedRequests = pendingLeaveRequests.flat();
            setPendingRequests(flattenedRequests);
            setFilteredRequests(flattenedRequests);
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching pending leave requests:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPendingLeaveRequests();
    }, [])
  );

  useEffect(() => {
    const filtered = pendingRequests.filter((request) =>
      request.employeeName.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredRequests(filtered);
  }, [searchInput, pendingRequests]);

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#000" : "#F0F0F0"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton />
        <Text
          style={[styles.title, colorScheme === "dark" && styles.titleDark]}
        >
          Pending Leave Requests
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color="#007AFF"
          />
        </TouchableOpacity>
      </View>
      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        <View
          style={[
            styles.searchContainer,
            colorScheme === "dark" && styles.searchContainerDark,
            isDesktop && styles.desktopSearchContainer,
          ]}
        >
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#8E8E93" : "#8E8E93"}
            style={styles.searchIcon}
          />
          <TextInput
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search by name"
            placeholderTextColor={
              colorScheme === "dark" ? "#8E8E93" : "#8E8E93"
            }
            value={searchInput}
            onChangeText={setSearchInput}
          />
        </View>
        {loading ? (
          <ActivityIndicator
            size="large"
            color="#007AFF"
            style={styles.loader}
          />
        ) : (
          <ScrollView style={styles.scrollContent}>
            <View
              style={[
                styles.requestList,
                isDesktop && styles.desktopRequestList,
              ]}
            >
              {filteredRequests.map((request) => (
                <Pressable
                  key={request._id}
                  onPress={() =>
                    navigation.navigate("Leave Request", {
                      employeeId: request.employeeId,
                      requestId: request._id,
                    })
                  }
                  style={({ pressed }) => [
                    styles.requestItem,
                    isDesktop && styles.desktopRequestItem,
                    colorScheme === "dark" && styles.requestItemDark,
                    {
                      backgroundColor: pressed
                        ? colorScheme === "dark"
                          ? "#333"
                          : "#F0F0F0"
                        : colorScheme === "dark"
                        ? "#222"
                        : "white",
                    },
                  ]}
                >
                  <View style={styles.requestInfo}>
                    <Text
                      style={[
                        styles.employeeName,
                        colorScheme === "dark" && styles.employeeNameDark,
                      ]}
                    >
                      {request.employeeName}
                    </Text>
                    <Text
                      style={[
                        styles.requestDetail,
                        colorScheme === "dark" && styles.requestDetailDark,
                      ]}
                    >
                      {moment(request.startDate).format("MMM D")} -{" "}
                      {moment(request.endDate).format("MMM D, YYYY")}
                    </Text>
                    <Text
                      style={[
                        styles.requestDetail,
                        colorScheme === "dark" && styles.requestDetailDark,
                      ]}
                    >
                      Reason: {request.reason}
                    </Text>
                  </View>
                  <Feather name="chevron-right" size={24} color="#C7C7CC" />
                </Pressable>
              ))}
            </View>
          </ScrollView>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  // ... (copy styles from SupervisorApproval and adjust as needed)
  // You'll need to add or modify styles for the new components like requestList, requestItem, etc.
});

export default PendingLeaveRequests;
