import React, { useState, useEffect, useMemo } from "react";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  Pressable,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useWindowDimensions,
  useColorScheme as useDeviceColorScheme,
} from "react-native";
import { Ionicons, Feather } from "@expo/vector-icons";
import axios from "axios";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import CustomLoading from "./CustomLoading"; // Add this import
import { signOut } from "firebase/auth";

const BackButton = ({ color }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Ionicons name="arrow-back" size={24} color={color} />
    </TouchableOpacity>
  );
};

const MarkAttendance = () => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const handleLogout = async () => {
    try {
      await signOut(FIREBASE_AUTH);
      navigation.reset({
        index: 0,
        routes: [{ name: "Login" }],
      });
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      const fetchEmployees = async () => {
        try {
          setLoading(true);

          const currentUser = FIREBASE_AUTH.currentUser;
          if (!currentUser) {
            console.log("No user logged in");
            return;
          }

          setCurrentUserEmail(currentUser.email);

          const db = getFirestore();
          const docRef = doc(db, "users", currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const isAdmin = userData.role === "admin";
            const isSupervisor = userData.role === "supervisor";
            setIsAdmin(isAdmin);
            setIsSupervisor(isSupervisor);

            let allEmployeesResponse;
            if (isAdmin) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees"
              );
            } else if (isSupervisor) {
              const supervisorName = userData.name;
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                {
                  params: { supervisorName: supervisorName },
                }
              );
            } else {
              const userEmail = currentUser.email;
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                {
                  params: { email: userEmail },
                }
              );
            }

            let employeesData = [];
            if (Array.isArray(allEmployeesResponse.data)) {
              employeesData = allEmployeesResponse.data;
            } else if (allEmployeesResponse.data) {
              employeesData = [allEmployeesResponse.data];
            }

            const sortedEmployees = employeesData.sort((a, b) =>
              a.employeeName.localeCompare(b.employeeName)
            );

            setEmployees(sortedEmployees);
            setFilteredEmployees(sortedEmployees);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching employees:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchEmployees();
    }, [])
  );

  useEffect(() => {
    const filtered = employees
      .filter((employee) => {
        return employee.employeeName
          ? employee.employeeName
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false;
      })
      .sort((a, b) => a.employeeName.localeCompare(b.employeeName));
    setFilteredEmployees(filtered);
  }, [searchInput, employees]);

  const renderEmployeeItem = (item, index) => {
    const isCurrentUser = item.email === currentUserEmail;
    return (
      <Pressable
        key={item._id}
        onPress={() =>
          navigation.navigate("Timesheet", {
            name: item.employeeName,
            id: item.employeeId,
            jobTitle: item.jobTitle,
            department: item.department,
          })
        }
        style={({ pressed }) => [
          styles.employeeItem,
          isCurrentUser && styles.currentUserItem,
          isDesktop && styles.desktopEmployeeItem,
          colorScheme === "dark" && styles.employeeItemDark,
          {
            backgroundColor: pressed
              ? isCurrentUser
                ? colorScheme === "dark"
                  ? "#1A3C66"
                  : "#E6F0FF"
                : colorScheme === "dark"
                ? "#333"
                : "#F0F0F0"
              : isCurrentUser
              ? colorScheme === "dark"
                ? "#0A2A52"
                : "#F0F7FF"
              : colorScheme === "dark"
              ? "#1C1C1E"
              : "white",
          },
        ]}
      >
        <View
          style={[
            styles.avatarContainer,
            isCurrentUser && styles.currentUserAvatar,
          ]}
        >
          <Text
            style={[
              styles.avatarText,
              isCurrentUser && styles.currentUserAvatarText,
            ]}
          >
            {item.employeeName.charAt(0)}
          </Text>
        </View>
        <View style={styles.employeeInfo}>
          <Text
            style={[
              styles.employeeName,
              isCurrentUser && styles.currentUserName,
              colorScheme === "dark" && styles.employeeNameDark,
            ]}
          >
            {item.employeeName}
            {isCurrentUser && <Text style={styles.youBadge}> (You)</Text>}
          </Text>
          <Text
            style={[
              styles.employeeDetail,
              isCurrentUser && styles.currentUserDetail,
              colorScheme === "dark" && styles.employeeDetailDark,
            ]}
          >
            {item.jobTitle}, {item.department}
          </Text>
        </View>
        <Feather
          name="chevron-right"
          size={24}
          color={
            isCurrentUser
              ? "#007AFF"
              : colorScheme === "dark"
              ? "#666"
              : "#C7C7CC"
          }
        />
      </Pressable>
    );
  };

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  if (loading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#000000"} />
        <Text
          style={[styles.title, colorScheme === "dark" && styles.titleDark]}
        >
          Mark Attendance
        </Text>
        <View style={styles.headerRightContainer}>
          <TouchableOpacity
            onPress={toggleColorScheme}
            style={styles.themeToggle}
          >
            <Feather
              name={colorScheme === "dark" ? "sun" : "moon"}
              size={24}
              color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleLogout} style={styles.logoutButton}>
            <Feather
              name="log-out"
              size={24}
              color={colorScheme === "dark" ? "#FFFFFF" : "#000000"}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        <View
          style={[
            styles.searchContainer,
            isDesktop && styles.desktopSearchContainer,
            colorScheme === "dark" && styles.searchContainerDark,
          ]}
        >
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#8E8E93" : "#8E8E93"}
            style={styles.searchIcon}
          />
          <TextInput
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search employees"
            placeholderTextColor={
              colorScheme === "dark" ? "#8E8E93" : "#8E8E93"
            }
            value={searchInput}
            onChangeText={setSearchInput}
          />
        </View>
        <ScrollView style={styles.scrollContent}>
          <View
            style={[
              styles.employeeList,
              isDesktop && styles.desktopEmployeeList,
            ]}
          >
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((item, index) =>
                renderEmployeeItem(item, index)
              )
            ) : (
              <Text
                style={[
                  styles.noEmployeesText,
                  colorScheme === "dark" && styles.noEmployeesTextDark,
                ]}
              >
                No employees found
              </Text>
            )}
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerDark: {
    backgroundColor: "#000",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1C1C1E",
    borderBottomColor: "#333",
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  titleDark: {
    color: "#FFFFFF",
  },
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  themeToggle: {
    padding: 8,
  },
  logoutButton: {
    padding: 8,
  },
  content: {
    flex: 1,
  },
  desktopContent: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  backButton: {
    padding: 8,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    marginHorizontal: 16,
    marginVertical: 12,
    paddingHorizontal: 12,
    height: 44,
  },
  desktopSearchContainer: {
    maxWidth: 400,
    alignSelf: "center",
    marginBottom: 20,
  },
  searchContainerDark: {
    backgroundColor: "#1C1C1E",
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#000",
  },
  searchInputDark: {
    color: "#FFFFFF",
  },
  scrollContent: {
    flex: 1,
  },
  employeeList: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignItems: "center",
  },
  desktopEmployeeList: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  employeeItem: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 12,
    marginVertical: 6,
    padding: 16,
    width: "90%",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  desktopEmployeeItem: {
    width: "calc(33.33% - 20px)",
    marginHorizontal: 10,
    marginVertical: 10,
  },
  employeeItemDark: {
    backgroundColor: "#1C1C1E",
  },
  avatarContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: "#007AFF",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
  avatarText: {
    color: "white",
    fontSize: 20,
    fontWeight: "600",
  },
  employeeInfo: {
    flex: 1,
  },
  employeeName: {
    fontSize: 18,
    fontWeight: "600",
    color: "#000",
    marginBottom: 4,
  },
  employeeNameDark: {
    color: "#FFFFFF",
  },
  employeeDetail: {
    fontSize: 14,
    color: "#666",
  },
  employeeDetailDark: {
    color: "#999",
  },
  currentUserItem: {
    backgroundColor: "#F0F7FF",
    borderWidth: 2,
    borderColor: "#007AFF",
  },
  currentUserAvatar: {
    backgroundColor: "#007AFF",
  },
  currentUserAvatarText: {
    color: "white",
  },
  currentUserName: {
    color: "#007AFF",
  },
  currentUserDetail: {
    color: "#333",
  },
  youBadge: {
    fontSize: 14,
    fontWeight: "normal",
    color: "#007AFF",
  },
  noEmployeesText: {
    fontSize: 16,
    color: "#8E8E93",
    textAlign: "center",
    marginTop: 24,
  },
  noEmployeesTextDark: {
    color: "#8E8E93",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
  // ... (you can add other dynamic styles here if needed)
});

export default MarkAttendance;
