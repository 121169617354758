import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import axios from "axios";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";
import moment from "moment-timezone";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import CustomAlert from "./CustomAlert";

const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const LeaveRequest = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supervisorName, setSupervisorName] = useState("");
  const [isSignatureChecked, setIsSignatureChecked] = useState({});

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [showCancel, setShowCancel] = useState(true);

  const { employeeId } = route.params; // Get employeeId from route params

  const showAlert = (message, title, onConfirm = null, showCancel = true) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertConfirm(() => onConfirm);
    setShowCancel(showCancel);
    setAlertVisible(true);
  };

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = FIREBASE_AUTH.currentUser;
      if (user) {
        try {
          const db = getFirestore();
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData && userData.name) {
              setSupervisorName(userData.name);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          showAlert("Failed to fetch user data", "Error", null, false);
        }
      }
    };

    const fetchLeaveRequests = async () => {
      try {
        console.log("Fetching leave requests for employee:", employeeId);
        const response = await axios.get(
          `https://sbg-backend.onrender.com/personal-leave/${employeeId}`
        );

        console.log("API Response:", response.data);

        if (response.data && Array.isArray(response.data)) {
          const pendingRequests = response.data.filter(
            (request) => request.status === "Pending"
          );
          setLeaveRequests(pendingRequests);
          const initialSignatureState = {};
          pendingRequests.forEach((req) => {
            initialSignatureState[req._id] = false;
          });
          setIsSignatureChecked(initialSignatureState);
        } else {
          console.error("Unexpected API response format:", response.data);
          showAlert("Unexpected data format from API", "Error", null, false);
        }
      } catch (error) {
        console.error("Error fetching leave request:", error);
        showAlert("Failed to fetch leave request", "Error", null, false);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
    fetchLeaveRequests();
  }, [employeeId]);

  const handleApprove = async (requestId) => {
    if (!isSignatureChecked[requestId]) {
      showAlert(
        "Please acknowledge the electronic signature before approving.",
        "Error"
      );
      return;
    }

    showAlert(
      "Are you sure you want to approve this leave request?",
      "Confirm Approval",
      async () => {
        try {
          await axios.put(
            `https://sbg-backend.onrender.com/personal-leave/${requestId}`,
            {
              status: "Approved",
              approvedBy: supervisorName,
            }
          );
          setLeaveRequests(
            leaveRequests.filter((req) => req._id !== requestId)
          );
          showAlert("Leave request approved", "Success", null, false);
        } catch (error) {
          console.error("Error approving leave request:", error);
          showAlert("Failed to approve leave request", "Error", null, false);
        }
      }
    );
  };

  const handleReject = async (requestId) => {
    if (!isSignatureChecked[requestId]) {
      showAlert(
        "Please acknowledge the electronic signature before rejecting.",
        "Error"
      );
      return;
    }

    showAlert(
      "Are you sure you want to reject this leave request?",
      "Confirm Rejection",
      async () => {
        try {
          await axios.put(
            `https://sbg-backend.onrender.com/personal-leave/${requestId}`,
            {
              status: "Rejected",
              approvedBy: supervisorName,
            }
          );
          setLeaveRequests(
            leaveRequests.filter((req) => req._id !== requestId)
          );
          showAlert("Leave request rejected", "Success", null, false);
        } catch (error) {
          console.error("Error rejecting leave request:", error);
          showAlert("Failed to reject leave request", "Error", null, false);
        }
      }
    );
  };

  const toggleSignature = (requestId) => {
    setIsSignatureChecked((prevState) => ({
      ...prevState,
      [requestId]: !prevState[requestId],
    }));
  };

  if (loading) {
    return (
      <View
        style={[
          styles.loadingContainer,
          colorScheme === "dark" && styles.loadingContainerDark,
        ]}
      >
        <ActivityIndicator
          size="large"
          color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
        />
      </View>
    );
  }

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text
          style={[
            styles.headerText,
            colorScheme === "dark" && styles.headerTextDark,
          ]}
        >
          Pending Leave Request
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.scrollView}>
        {leaveRequests.length === 0 ? (
          <Text
            style={[
              styles.message,
              colorScheme === "dark" && styles.messageDark,
            ]}
          >
            No pending leave request found
          </Text>
        ) : (
          leaveRequests.map((request) => (
            <View
              key={request._id}
              style={[
                styles.card,
                colorScheme === "dark" && styles.cardDark,
                isDesktop && styles.desktopCard,
              ]}
            >
              <DetailItem
                label="Employee Name"
                value={request.employeeName}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Employee ID"
                value={request.employeeId}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Client Name"
                value={request.clientName}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Leave Type"
                value={request.leaveType}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Hours Claimed"
                value={request.hoursClaimed.toString()}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Date From"
                value={moment(request.dateFrom).format("YYYY-MM-DD")}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Date To"
                value={moment(request.dateTo).format("YYYY-MM-DD")}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Total Days Off"
                value={request.totalDaysOff.toString()}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Reason for Absence"
                value={request.reasonForAbsence}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Status"
                value={request.status}
                colorScheme={colorScheme}
              />
              <DetailItem
                label="Created At"
                value={moment(request.createdAt).format("YYYY-MM-DD HH:mm")}
                colorScheme={colorScheme}
              />

              <View style={styles.signatureContainer}>
                <TouchableOpacity
                  style={styles.checkbox}
                  onPress={() => toggleSignature(request._id)}
                >
                  {isSignatureChecked[request._id] && (
                    <Feather name="check" size={20} color="#4CAF50" />
                  )}
                </TouchableOpacity>
                <Text
                  style={[
                    styles.signatureText,
                    colorScheme === "dark" && styles.signatureTextDark,
                  ]}
                >
                  I acknowledge that my electronic signature is legally binding.
                </Text>
              </View>

              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[
                    styles.button,
                    styles.approveButton,
                    colorScheme === "dark" && styles.approveButtonDark,
                    !isSignatureChecked[request._id] && styles.buttonDisabled,
                  ]}
                  onPress={() => handleApprove(request._id)}
                  disabled={!isSignatureChecked[request._id]}
                >
                  <Text
                    style={[
                      styles.buttonText,
                      !isSignatureChecked[request._id] &&
                        styles.buttonTextDisabled,
                    ]}
                  >
                    Approve
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.button,
                    styles.rejectButton,
                    colorScheme === "dark" && styles.rejectButtonDark,
                    !isSignatureChecked[request._id] && styles.buttonDisabled,
                  ]}
                  onPress={() => handleReject(request._id)}
                  disabled={!isSignatureChecked[request._id]}
                >
                  <Text
                    style={[
                      styles.buttonText,
                      !isSignatureChecked[request._id] &&
                        styles.buttonTextDisabled,
                    ]}
                  >
                    Reject
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ))
        )}
      </ScrollView>
      <CustomAlert
        visible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
        onConfirm={alertConfirm}
        showCancel={showCancel}
      />
    </SafeAreaView>
  );
};

const DetailItem = ({ label, value, colorScheme }) => (
  <View style={styles.detailItem}>
    <Text
      style={[
        styles.detailLabel,
        colorScheme === "dark" && styles.detailLabelDark,
      ]}
    >
      {label}:
    </Text>
    <Text
      style={[
        styles.detailValue,
        colorScheme === "dark" && styles.detailValueDark,
      ]}
    >
      {value}
    </Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  scrollView: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  cardDark: {
    backgroundColor: "#1E1E1E",
    shadowColor: "#FFF",
  },
  desktopCard: {
    maxWidth: 600,
    alignSelf: "center",
    width: "100%",
  },
  detailItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  detailLabel: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
  },
  detailLabelDark: {
    color: "#FFFFFF",
  },
  detailValue: {
    fontSize: 16,
    fontWeight: "400",
    color: "#666",
    textAlign: "right",
    flex: 1,
    marginLeft: 8,
  },
  detailValueDark: {
    color: "#CCCCCC",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
  },
  button: {
    flex: 1,
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  approveButton: {
    backgroundColor: "#4CAF50",
    marginRight: 8,
  },
  approveButtonDark: {
    backgroundColor: "#43A047",
  },
  rejectButton: {
    backgroundColor: "#F44336",
    marginLeft: 8,
  },
  rejectButtonDark: {
    backgroundColor: "#E53935",
  },
  buttonDisabled: {
    opacity: 0.5,
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "600",
  },
  buttonTextDisabled: {
    color: "#AAAAAA",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainerDark: {
    backgroundColor: "#121212",
  },
  message: {
    textAlign: "center",
    fontSize: 16,
    color: "#666",
    marginTop: 20,
  },
  messageDark: {
    color: "#AAAAAA",
  },
  signatureContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: "#4CAF50",
    borderRadius: 4,
    marginRight: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  signatureText: {
    fontSize: 16,
    color: "#333",
    flex: 1,
  },
  signatureTextDark: {
    color: "#FFFFFF",
  },
});

export default LeaveRequest;
