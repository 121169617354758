import React, { useState, useEffect, useMemo } from "react";
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  useColorScheme as useDeviceColorScheme,
  Alert,
  Dimensions,
} from "react-native";
import { FIREBASE_DB, FIREBASE_AUTH } from "../../FirebaseConfig";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Feather } from "@expo/vector-icons";
import CustomLoading from "./CustomLoading";
import { useNavigation } from "@react-navigation/native";
import CustomAlert from "./CustomAlert";
import axios from "axios";

const { width } = Dimensions.get("window");

const AuthDatabase = () => {
  const navigation = useNavigation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const [editingUser, setEditingUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userDoc = await getDoc(doc(FIREBASE_DB, "users", user.uid));
        setIsAdmin(userDoc.data()?.role === "admin");
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(FIREBASE_DB, "users");
        const userSnapshot = await getDocs(usersCollection);
        const userList = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Sort users alphabetically by name
        const sortedUsers = userList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setUsers(sortedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.role.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEdit = (user) => {
    if (isAdmin || (currentUser && currentUser.uid === user.id)) {
      setEditingUser(user);
    } else {
      Alert.alert(
        "Permission Denied",
        "You can only edit your own information."
      );
    }
  };

  const handleSave = async () => {
    if (!currentUser || (!isAdmin && currentUser.uid !== editingUser.id)) {
      Alert.alert(
        "Error",
        "You don't have permission to edit this information"
      );
      return;
    }

    try {
      const userRef = doc(FIREBASE_DB, "users", editingUser.id);
      await updateDoc(userRef, {
        name: editingUser.name,
        email: editingUser.email,
        role: editingUser.role,
      });

      setUsers(
        users.map((user) => (user.id === editingUser.id ? editingUser : user))
      );
      setEditingUser(null);
      Alert.alert("Success", "User information updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      Alert.alert(
        "Error",
        "Failed to update user information. Please try again."
      );
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
  };

  const handleAddUser = () => {
    if (isAdmin) {
      navigation.navigate("AdminRegistration");
    } else {
      Alert.alert("Permission Denied", "Only admins can add new users.");
    }
  };

  const handleDelete = (user) => {
    console.log("Delete initiated for user:", user);

    if (!isAdmin) {
      console.log("Not an admin, cancelling delete");
      Alert.alert("Error", "Only administrators can delete users");
      return;
    }

    setUserToDelete(user);
    setDeleteAlertVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (!userToDelete) {
        console.error("No user selected for deletion");
        return;
      }

      setLoading(true);
      console.log("Attempting to delete user:", userToDelete);

      const response = await axios.post(
        "https://sbg-backend.onrender.com/deleteUser",
        {
          email: userToDelete.email,
          userId: userToDelete.id,
        }
      );

      if (response.data.success) {
        // Update local state to remove the deleted user
        setUsers(users.filter((user) => user.id !== userToDelete.id));

        // Reset delete-related states
        setDeleteAlertVisible(false);
        setUserToDelete(null);

        // Show success message
        Alert.alert("Success", "User deleted successfully");
      } else {
        throw new Error(response.data.message || "Failed to delete user");
      }
    } catch (error) {
      console.error("Error in deletion:", error);
      Alert.alert(
        "Error",
        error.response?.data?.message ||
          error.message ||
          "Failed to delete user"
      );
    } finally {
      setLoading(false);
    }
  };

  const renderUserItem = ({ item }) => (
    <View
      style={[styles.userItem, colorScheme === "dark" && styles.userItemDark]}
    >
      {editingUser && editingUser.id === item.id ? (
        <View style={styles.editContainer}>
          <TextInput
            style={[styles.input, colorScheme === "dark" && styles.inputDark]}
            value={editingUser.name}
            onChangeText={(text) =>
              setEditingUser({ ...editingUser, name: text })
            }
            placeholder="Name"
          />
          <TextInput
            style={[styles.input, colorScheme === "dark" && styles.inputDark]}
            value={editingUser.email}
            onChangeText={(text) =>
              setEditingUser({ ...editingUser, email: text })
            }
            placeholder="Email"
          />
          <TextInput
            style={[styles.input, colorScheme === "dark" && styles.inputDark]}
            value={editingUser.role}
            onChangeText={(text) =>
              setEditingUser({ ...editingUser, role: text })
            }
            placeholder="Role"
          />
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.saveButton} onPress={handleSave}>
              <Text style={styles.buttonText}>Save</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancelButton}
              onPress={handleCancel}
            >
              <Text style={styles.buttonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <>
          <View style={styles.userInfo}>
            <Text
              style={[
                styles.userName,
                colorScheme === "dark" && styles.textDark,
              ]}
              numberOfLines={1}
            >
              {item.name}
            </Text>
            <Text
              style={[
                styles.userEmail,
                colorScheme === "dark" && styles.textDark,
              ]}
              numberOfLines={1}
            >
              {item.email}
            </Text>
            <Text
              style={[
                styles.userRole,
                colorScheme === "dark" && styles.textDark,
              ]}
              numberOfLines={1}
            >
              {item.role}
            </Text>
          </View>
          <View style={styles.actionButtons}>
            {(isAdmin || (currentUser && currentUser.uid === item.id)) && (
              <TouchableOpacity
                style={styles.editButton}
                onPress={() => handleEdit(item)}
              >
                <Feather name="edit" size={18} color="#007AFF" />
              </TouchableOpacity>
            )}
            {isAdmin && item.role !== "admin" && (
              <TouchableOpacity
                style={styles.deleteButton}
                onPress={() => {
                  console.log("Delete button pressed for user:", item);
                  handleDelete(item);
                }}
              >
                <Feather name="trash-2" size={18} color="#FF3B30" />
              </TouchableOpacity>
            )}
          </View>
        </>
      )}
    </View>
  );

  if (loading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={[styles.container, dynamicStyles.container]}>
      <View style={styles.header}>
        <View style={styles.searchContainer}>
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#fff" : "#000"}
            style={styles.searchIcon}
          />
          <TextInput
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search users..."
            placeholderTextColor={colorScheme === "dark" ? "#888" : "#666"}
            value={searchQuery}
            onChangeText={setSearchQuery}
          />
          {searchQuery !== "" && (
            <TouchableOpacity onPress={() => setSearchQuery("")}>
              <Feather
                name="x"
                size={20}
                color={colorScheme === "dark" ? "#fff" : "#000"}
                style={styles.clearIcon}
              />
            </TouchableOpacity>
          )}
        </View>
        {isAdmin && (
          <TouchableOpacity
            style={[styles.addButton, dynamicStyles.addButton]}
            onPress={handleAddUser}
          >
            <Feather name="plus" size={24} color="#007AFF" />
          </TouchableOpacity>
        )}
      </View>
      <FlatList
        data={filteredUsers}
        keyExtractor={(item) => item.id}
        renderItem={renderUserItem}
        ListEmptyComponent={
          <Text
            style={[
              styles.emptyText,
              colorScheme === "dark" && styles.textDark,
            ]}
          >
            No users found
          </Text>
        }
        contentContainerStyle={styles.listContainer}
      />
      <CustomAlert
        visible={deleteAlertVisible}
        title="Delete User"
        message={`Are you sure you want to delete ${userToDelete?.name}?\n\nThis action cannot be undone.`}
        onClose={() => {
          setDeleteAlertVisible(false);
          setUserToDelete(null);
        }}
        onConfirm={handleConfirmDelete}
        showCancel={true}
      />
      {loading && <CustomLoading />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    padding: 16,
  },
  userItem: {
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
    padding: 12,
    marginBottom: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userItemDark: {
    backgroundColor: "#333",
  },
  userInfo: {
    flex: 1,
    marginRight: 8,
  },
  userName: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 2,
  },
  userEmail: {
    fontSize: 14,
    color: "#666",
    marginBottom: 2,
  },
  userRole: {
    fontSize: 14,
    color: "#888",
  },
  textDark: {
    color: "#fff",
  },
  editContainer: {
    width: "100%",
  },
  input: {
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
    marginBottom: 8,
    paddingHorizontal: 8,
    backgroundColor: "#fff",
  },
  inputDark: {
    borderColor: "#666",
    backgroundColor: "#444",
    color: "#fff",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  editButton: {
    padding: 8,
  },
  saveButton: {
    backgroundColor: "#007AFF",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    flex: 1,
    marginRight: 8,
  },
  cancelButton: {
    backgroundColor: "#FF3B30",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    flex: 1,
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  searchInput: {
    flex: 1,
    height: 40,
    fontSize: 16,
    color: "#000",
  },
  searchInputDark: {
    color: "#fff",
    backgroundColor: "#333",
  },
  searchIcon: {
    marginRight: 10,
  },
  clearIcon: {
    marginLeft: 10,
  },
  emptyText: {
    textAlign: "center",
    fontSize: 16,
    color: "#666",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    paddingHorizontal: 16,
  },
  addButton: {
    padding: 10,
    borderRadius: 8,
  },
  actionButtons: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  deleteButton: {
    padding: 8,
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f5f5f5",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
  addButton: {
    backgroundColor: colorScheme === "dark" ? "#333" : "#f0f0f0",
  },
});

export default AuthDatabase;
