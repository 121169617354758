import React, { useState, useEffect, useMemo } from "react";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  ActivityIndicator,
  Pressable,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import axios from "axios";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Feather } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment-timezone";
import CustomLoading from "./CustomLoading";

const BackButton = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color="#007AFF" />
    </TouchableOpacity>
  );
};

const SupervisorApproval = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [payPeriod, setPayPeriod] = useState("");
  const [isBeforeNoonTuesday, setIsBeforeNoonTuesday] = useState(true);
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  const getCurrentPayPeriod = () => {
    const now = moment().tz("America/Phoenix");
    const thisSunday = now.clone().startOf("week");
    const tuesdayNoon = thisSunday
      .clone()
      .add(2, "days")
      .hour(12)
      .minute(0)
      .second(0);

    let payPeriodStart;
    let payPeriodEnd;
    let beforeNoon = true;

    if (now.isBefore(tuesdayNoon)) {
      payPeriodStart = thisSunday.clone().subtract(1, "week");
      payPeriodEnd = payPeriodStart.clone().endOf("week");
      beforeNoon = true;
    } else {
      payPeriodStart = thisSunday.clone();
      payPeriodEnd = payPeriodStart.clone().endOf("week");
      beforeNoon = false;
    }

    return { payPeriodStart, payPeriodEnd, beforeNoon };
  };

  useFocusEffect(
    React.useCallback(() => {
      const fetchEmployees = async () => {
        try {
          setLoading(true);

          const currentUser = FIREBASE_AUTH.currentUser;
          if (!currentUser) {
            console.log("No user logged in");
            return;
          }

          const db = getFirestore();
          const docRef = doc(db, "users", currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const isAdmin = userData.role === "admin";
            const isSupervisor = userData.role === "supervisor";
            const supervisorName = userData.name;

            let allEmployeesResponse;
            if (isAdmin) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees"
              );
            } else if (isSupervisor) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                { params: { supervisorName: supervisorName } }
              );
            } else {
              const userEmail = currentUser.email;
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                { params: { email: userEmail } }
              );
            }

            const { payPeriodStart, payPeriodEnd, beforeNoon } =
              getCurrentPayPeriod();

            setPayPeriod(
              `${payPeriodStart.format("MMMM D, YYYY")} - ${payPeriodEnd.format(
                "MMMM D, YYYY"
              )}`
            );
            setIsBeforeNoonTuesday(beforeNoon);

            const employeesWithStatus = await Promise.all(
              allEmployeesResponse.data.map(async (employee) => {
                const attendanceResponse = await axios.get(
                  `https://sbg-backend.onrender.com/attendances/${employee.employeeId}`
                );

                const currentPeriodAttendance = attendanceResponse.data.find(
                  (attendance) => {
                    const attendanceWeekStart = moment.tz(
                      attendance.weekStart,
                      "America/Phoenix"
                    );
                    return attendanceWeekStart.isSame(payPeriodStart, "day");
                  }
                );

                const submissionStatus = currentPeriodAttendance
                  ? currentPeriodAttendance.isSubmitted
                  : false;
                const approvedStatus = currentPeriodAttendance
                  ? currentPeriodAttendance.approved
                  : false;

                const leaveResponse = await axios.get(
                  `https://sbg-backend.onrender.com/personal-leave/${employee.employeeId}`
                );
                const hasPendingLeaveRequest = leaveResponse.data.some(
                  (leave) => leave.status === "Pending"
                );

                return {
                  ...employee,
                  submitted: submissionStatus,
                  approved: approvedStatus,
                  hasPendingLeaveRequest,
                };
              })
            );

            const sortedEmployees = employeesWithStatus.sort((a, b) =>
              a.employeeName.localeCompare(b.employeeName)
            );

            setEmployees(sortedEmployees);
            setFilteredEmployees(sortedEmployees);
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching employees:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchEmployees();
    }, [])
  );

  useEffect(() => {
    const filtered = employees
      .filter((employee) =>
        employee.employeeName.toLowerCase().includes(searchInput.toLowerCase())
      )
      .sort((a, b) => a.employeeName.localeCompare(b.employeeName));
    setFilteredEmployees(filtered);
  }, [searchInput, employees]);

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  if (loading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#000" : "#F0F0F0"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton />
        <Text
          style={[styles.title, colorScheme === "dark" && styles.titleDark]}
        >
          Supervisor Approval
        </Text>
        <View style={styles.headerRightContainer}>
          <TouchableOpacity
            onPress={toggleColorScheme}
            style={styles.themeToggle}
          >
            <Feather
              name={colorScheme === "dark" ? "sun" : "moon"}
              size={24}
              color="#007AFF"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.logoutButton}
            onPress={() => {
              FIREBASE_AUTH.signOut()
                .then(() => console.log("User signed out!"))
                .catch((error) => console.error("Sign Out Error", error));
            }}
          >
            <Feather name="log-out" size={24} color="#007AFF" />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.content, isDesktop && styles.desktopContent]}>
        <Text
          style={[
            styles.payPeriodText,
            colorScheme === "dark" && styles.payPeriodTextDark,
          ]}
        >
          Pay Period: {payPeriod}
        </Text>
        <Text
          style={[
            styles.payPeriodNote,
            colorScheme === "dark" && styles.payPeriodNoteDark,
          ]}
        >
          {isBeforeNoonTuesday
            ? "Managing submissions for the previous pay period. Pay period will switch after Tuesday at noon."
            : "Managing submissions for the current pay period. Pay period switched after Tuesday at noon."}{" "}
          To view full submission history, click on an employee's name.
        </Text>
        <View
          style={[
            styles.searchContainer,
            colorScheme === "dark" && styles.searchContainerDark,
            isDesktop && styles.desktopSearchContainer,
          ]}
        >
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#8E8E93" : "#8E8E93"}
            style={styles.searchIcon}
          />
          <TextInput
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search by name"
            placeholderTextColor={
              colorScheme === "dark" ? "#8E8E93" : "#8E8E93"
            }
            value={searchInput}
            onChangeText={setSearchInput}
          />
        </View>
        <ScrollView style={styles.scrollContent}>
          <View
            style={[
              styles.employeeList,
              isDesktop && styles.desktopEmployeeList,
            ]}
          >
            {filteredEmployees.map((item) => (
              <Pressable
                key={item._id}
                onPress={() =>
                  navigation.navigate("Timeesheets/Personal Leave", {
                    employeeId: item.employeeId,
                  })
                }
                style={({ pressed }) => [
                  styles.employeeItem,
                  isDesktop && styles.desktopEmployeeItem,
                  colorScheme === "dark" && styles.employeeItemDark,
                  {
                    backgroundColor: pressed
                      ? colorScheme === "dark"
                        ? "#333"
                        : "#F0F0F0"
                      : colorScheme === "dark"
                      ? "#222"
                      : "white",
                  },
                ]}
              >
                <View style={styles.avatarContainer}>
                  <Text style={styles.avatarText}>
                    {item.employeeName.charAt(0)}
                  </Text>
                </View>
                <View style={styles.employeeInfo}>
                  <Text
                    style={[
                      styles.employeeName,
                      colorScheme === "dark" && styles.employeeNameDark,
                    ]}
                  >
                    {item.employeeName}
                  </Text>
                  <Text
                    style={[
                      styles.employeeDetail,
                      colorScheme === "dark" && styles.employeeDetailDark,
                    ]}
                  >
                    {item.jobTitle}, {item.department}
                  </Text>
                  <View style={styles.statusContainer}>
                    <Text
                      style={[
                        styles.statusText,
                        item.submitted ? styles.submitted : styles.notSubmitted,
                      ]}
                    >
                      {item.submitted ? "Submitted" : "Not Submitted"}
                    </Text>
                    <Text
                      style={[
                        styles.statusText,
                        item.approved ? styles.approved : styles.notApproved,
                      ]}
                    >
                      {item.approved ? "Approved" : "Not Approved"}
                    </Text>
                  </View>
                  {item.hasPendingLeaveRequest && (
                    <TouchableOpacity
                      style={styles.leaveRequestButton}
                      onPress={() =>
                        navigation.navigate("Leave Request", {
                          employeeId: item.employeeId,
                        })
                      }
                    >
                      <Text style={styles.leaveRequestButtonText}>
                        View Leave Request
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <Feather name="chevron-right" size={24} color="#C7C7CC" />
              </Pressable>
            ))}
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerDark: {
    backgroundColor: "#000",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#121212",
    borderBottomColor: "#333",
  },
  backButton: {
    padding: 8,
  },
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  themeToggle: {
    marginRight: 16,
  },
  logoutButton: {
    padding: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  titleDark: {
    color: "#fff",
  },
  content: {
    flex: 1,
  },
  desktopContent: {
    paddingHorizontal: 20,
    maxWidth: 1200,
    alignSelf: "center",
    width: "100%",
  },
  payPeriodText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#000",
    textAlign: "center",
    marginVertical: 10,
  },
  payPeriodTextDark: {
    color: "#fff",
  },
  payPeriodNote: {
    fontSize: 14,
    color: "#666",
    textAlign: "center",
    marginBottom: 10,
  },
  payPeriodNoteDark: {
    color: "#CCCCCC",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    marginHorizontal: 16,
    marginVertical: 12,
    paddingHorizontal: 12,
    height: 44,
  },
  desktopSearchContainer: {
    maxWidth: 600,
    alignSelf: "center",
  },
  searchContainerDark: {
    backgroundColor: "#222",
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#000",
  },
  searchInputDark: {
    color: "#fff",
  },
  scrollContent: {
    flex: 1,
  },
  employeeList: {
    paddingHorizontal: 16,
  },
  desktopEmployeeList: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  employeeItem: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 12,
    marginVertical: 6,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  desktopEmployeeItem: {
    width: "calc(33.33% - 20px)",
    marginHorizontal: 10,
    marginVertical: 10,
  },
  employeeItemDark: {
    backgroundColor: "#222",
  },
  avatarContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#007AFF",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  avatarText: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  employeeInfo: {
    flex: 1,
  },
  employeeName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#000",
  },
  employeeNameDark: {
    color: "#fff",
  },
  employeeDetail: {
    fontSize: 14,
    color: "#8E8E93",
    marginTop: 2,
  },
  employeeDetailDark: {
    color: "#C7C7CC",
  },
  statusContainer: {
    flexDirection: "row",
    marginTop: 4,
  },
  statusText: {
    fontSize: 12,
    fontWeight: "500",
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 4,
    marginRight: 8,
  },
  submitted: {
    backgroundColor: "#E1F5FE",
    color: "#0288D1",
  },
  notSubmitted: {
    backgroundColor: "#FFF3E0",
    color: "#FF9800",
  },
  approved: {
    backgroundColor: "#E8F5E9",
    color: "#4CAF50",
  },
  notApproved: {
    backgroundColor: "#FFEBEE",
    color: "#F44336",
  },
  leaveRequestButton: {
    backgroundColor: "#007AFF",
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginTop: 8,
    alignSelf: "flex-start",
  },
  leaveRequestButtonText: {
    color: "white",
    fontSize: 14,
    fontWeight: "600",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
  // ... (you can add other dynamic styles here if needed)
});

export default SupervisorApproval;
