import React, { useEffect } from "react";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";

const GlobalBackHandler = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (Platform.OS !== "web") {
      // Native platforms (Android, iOS)
      const BackHandler = require("react-native").BackHandler;
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        () => {
          if (navigation.canGoBack()) {
            navigation.goBack();
            return true;
          }
          return false;
        }
      );

      return () => backHandler.remove();
    } else {
      // Web platform
      const handlePopState = (event) => {
        if (navigation.canGoBack()) {
          event.preventDefault();
          navigation.goBack();
        }
      };

      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [navigation]);

  return null;
};

export default GlobalBackHandler;
